import { shallowEqual, useSelector } from 'react-redux';

const CLUSTERS_COUNT_FOR_SCHEMA = 6;

type ClustersBySchemaType = (Card | Cluster)[];

type UseHeaderType = (
  selectForClusters: (state: AppState) => (Card | Cluster | undefined)[],
) => {
  // Cписок кластеров для JSON LD
  clustersBySchema: ClustersBySchemaType;
};

/**
 * Хук для получения списка кластеров для JSON LD
 * @param selectForClusters - селектор кластеров страницы.
 */
export const useHeader: UseHeaderType = (selectForClusters) => {
  const clusters = useSelector(selectForClusters, shallowEqual);

  return {
    clustersBySchema: clusters
      .filter(Boolean)
      .slice(0, CLUSTERS_COUNT_FOR_SCHEMA) as ClustersBySchemaType,
  };
};
