import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { SberWidgetIcon } from 'icons';

import { getSberLink } from '../../utils';

import s from './styles.module.css';

type SberWidgetPropsType = {
  erid: string;
  className?: string;
};

/**
 * Рекламный виджет сбера.
 * @param props - пропсы
 * @param props.erid - уникальный токен для ссылки СберВиджета;
 * @param props.className - дополнительный класс.
 */
const SberWidgetComponent = function SberWidget({
  erid,
  className,
}: SberWidgetPropsType) {
  return (
    <div className={cn(s.root, className)}>
      <a
        href={getSberLink(erid)}
        className={s.container}
        target="_blank"
        rel="noreferrer"
      >
        <SberWidgetIcon className={s.icon} />
      </a>
      <div className={s.adMeta}>
        <span className={s.title}>Реклама</span>
        <span className={s.content}>Рекламодатель: ПАО Сбербанк</span>
      </div>
    </div>
  );
};

export const SberWidget = withErrorBoundary(SberWidgetComponent);
