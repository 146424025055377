import React from 'react';

import { GREEN_PROJECT_IDS } from 'common/routes/home/constants';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';

import { RecommendationWidget } from '../RecommendationWidget';
import { TopicNewsWidgetRedesign } from '../TopicNewsWidgetRedesign/components/TopicNewsWidget';

import { WIDGETS_BY_PROJECT_CONFIG } from './constants';

type GetWidgetsPropsType = {
  projectId: ProjectType['id'];
  projectAlias: ProjectType['alias'];
  clusterId: Cluster['id'];
  topicAlias: TopicType['alias'];
  floorIndex: number;
  isSecondLevelWithoutEmbeds: boolean;
  isGreenTopic?: boolean;
};

/**
 * Хук, который отдает необходимые виджеты на втором уровне
 * @param props - пропсы
 * @param props.clusterId - id кластера;
 * @param props.projectId - id вертикали;
 * @param props.topicAlias - alias топика;
 * @param props.floorIndex - индекс итерируемого этажа;
 * @param props.isSecondLevelWithoutEmbeds - флаг, что не нужны эмбеды на втором уровне;
 * @param props.isGreenTopic - флаг, что зеленая рубрика.
 * @returns
 *  Массив, состоящий из двух виджетов:
 *   - Первый – эмбед виджет (на компактной версии отображаем под баннером)
 *   - Второй – виджет со списком новостей (скрываем на компактной версии)
 */
export const getSecondLevelWidgetOneColumn = ({
  clusterId,
  projectId,
  topicAlias,
  floorIndex,
  isSecondLevelWithoutEmbeds,
  isGreenTopic,
}: GetWidgetsPropsType) => {
  const isRenderWidget =
    !isSecondLevelWithoutEmbeds && (floorIndex === 0 || floorIndex === 1);

  if (GREEN_PROJECT_IDS.includes(projectId) || isGreenTopic) {
    return [
      isRenderWidget
        ? WIDGETS_BY_PROJECT_CONFIG[projectId][floorIndex + 1]
        : null,
      <RecommendationWidget
        key={`${floorIndex}RecommendationWidget`}
        rcmKey={RCM_BLOCK_TYPE.greenTopNewsWidgetDesktop}
        clusterId={clusterId}
      />,
    ];
  }

  return [
    isRenderWidget
      ? WIDGETS_BY_PROJECT_CONFIG[projectId][floorIndex + 1]
      : null,
    <TopicNewsWidgetRedesign
      key={`${floorIndex}TopicNewsWidgetRedesign`}
      topicAlias={topicAlias}
      newsCount={7}
      level={floorIndex + 1}
    />,
  ];
};
