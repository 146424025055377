import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CardConfigContextProvider } from 'common/components/CardLayout/context';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { useCardConfigValue } from 'desktop/hooks/useCardConfigValue';

type ContextWrapperPropsType = {
  children: React.ReactNode;
  selectClustersIds: (state: AppState) => string[];
  rcmBlockType?: RCM_BLOCK_TYPE;
};

/**
 * Обертка топ100 для кластеров.
 * @param props - пропсы
 * @param props.children - дочерний элемент, определяемый родителем для вставки;
 * @param props.selectClustersIds - функция-селектор для получения массива id кластеров;
 * @param props.rcmBlockType - тип блока, для которого генерируется разметка рекомендаций.
 */
const CardContextWrapperComponent = function CardContextWrapper({
  children,
  selectClustersIds,
  rcmBlockType,
}: ContextWrapperPropsType) {
  const clustersIds = useSelector(selectClustersIds, shallowEqual);

  const { value } = useCardConfigValue({
    clustersIds,
    rcmBlockType,
  });

  return (
    <CardConfigContextProvider value={value}>
      {children}
    </CardConfigContextProvider>
  );
};

export const CardContextWrapper = memo(CardContextWrapperComponent);
