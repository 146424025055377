import { AsyncThunk } from '@reduxjs/toolkit';
import React, { useCallback, useMemo, useState } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useNewsFeedLoad } from 'common/hooks/useNewsFeedLoad';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { FetchRecsType } from 'common/redux/typings';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { PageContextForTrigger } from 'desktop/pages/context';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CHUNK_FIFTH, CHUNK_FOURTH } from '../BaseRedesign/constants';

import { RECS_TO_LOAD, START_LOADER_COUNTER_VALUE } from './config';

import s from './styles.module.css';

const getLength = (counter: number) => {
  if (counter === START_LOADER_COUNTER_VALUE) {
    return CHUNK_FOURTH;
  }

  return CHUNK_FIFTH;
};

type TriggerPropsType = {
  ComponentRecommends: React.ElementType;
  fetchRecsData: AsyncThunk<void, FetchRecsType, {}>;
  excludedRecsIds: string[];
  rcmBlockType?: RCM_BLOCK_TYPE;
  hasNextPage: boolean;
};

/**
 * Компонент для подгрузки кластеров.
 * @param props - пропсы
 * @param props.ComponentRecommends - компонент для подгрузки рекомендов;
 * @param props.fetchRecsData - функция получения данных рекоммендов;
 * @param props.excludedRecsIds - айдишники исключаемых кластеров для рекомендов;
 * @param props.rcmBlockType - алиас, под которым хранится BlockID в сторе;
 * @param props.hasNextPage - флаг для возможности подгрузки кластеров.
 */
const TriggerComponent = function Trigger({
  ComponentRecommends,
  fetchRecsData,
  excludedRecsIds,
  rcmBlockType,
  hasNextPage,
}: TriggerPropsType) {
  const dispatch = useAppDispatch();

  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const [triggerReady, setTriggerReady] = useState(false);
  const [loaderCounter, setLoaderCounter] = useState(
    START_LOADER_COUNTER_VALUE,
  );

  const contextValue = useMemo(
    () => ({ triggerReady, setTriggerReady }),
    [triggerReady],
  );

  const loadRecommendations = useCallback(
    () =>
      dispatch(
        fetchRecsData({
          length: forceRedesign ? getLength(loaderCounter) : RECS_TO_LOAD,
          excludedClustersIds: excludedRecsIds,
          rcmBlockType,
        }),
      ),
    [
      dispatch,
      forceRedesign,
      fetchRecsData,
      excludedRecsIds,
      rcmBlockType,
      loaderCounter,
    ],
  );

  const { triggerComponent } = useNewsFeedLoad({
    callback: loadRecommendations,
    forceLoader: hasNextPage,
    setLoaderCounter,
  });

  return (
    <PageContextForTrigger.Provider value={contextValue}>
      {/* Компонент для подгрузки рекомендов (если ее реализовывать не обособленно, то проблемы с бесконечным циклом в useEffect) */}
      <ComponentRecommends />
      {triggerReady && hasNextPage && (
        <div className={s.trigger}>{triggerComponent}</div>
      )}
    </PageContextForTrigger.Provider>
  );
};

export const Trigger = withErrorBoundary(TriggerComponent);
