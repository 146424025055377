import _chunk from 'lodash.chunk';
import React, { useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { RamblerCalendar } from 'common/components/RamblerEmbeds/RamblerCalendar';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectHumanCenteredStrategyValue } from 'common/redux/runtime/selectors';
import { selectIsGigaChatShow } from 'common/redux/selectors';
import { getCutClustersCount } from 'common/routes/utils';
import { BANNERS } from 'config/common/banners/typings';
import { HUMAN_CENTERED_STRATEGY_SPLIT_VALUES } from 'config/common/splits/typings';
import { useAppSelector } from 'store/hooks';
import { getGlobalIndex } from 'utils/getGlobalIndex';

import { FirstLevel } from './components/FirstLevel';
import { SecondLevel } from './components/SecondLevel';
import { CHUNK_LAST, INJECTORS, SLICE_CONFIG } from './constants';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_list_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch.css';

type BaseRedesignPropsType = {
  clusterIds: Card['id'][];
  puids: PuidsType;
};

/**
 * Базовый костяк для всей домашней страницы Новостей.
 * @param props - пропсы
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы.
 */
const BaseRedesignComponent = function BaseRedesign({
  clusterIds,
  puids,
}: BaseRedesignPropsType) {
  const gigaEnabled = useAppSelector(selectIsGigaChatShow);
  const humanCenteredStrategyValue = useAppSelector(
    selectHumanCenteredStrategyValue,
  );

  const slicedClusters = useMemo(() => {
    const copyClusterIds = [...clusterIds];

    if (gigaEnabled) {
      const deleteCount = getCutClustersCount(humanCenteredStrategyValue);
      const startIndex =
        humanCenteredStrategyValue ===
        HUMAN_CENTERED_STRATEGY_SPLIT_VALUES.on_1_4
          ? 1
          : 0;

      copyClusterIds.splice(startIndex, deleteCount);
    }

    const sliced = SLICE_CONFIG.map((value) =>
      copyClusterIds.length > 0 ? copyClusterIds.splice(0, value) : undefined,
    ).filter((val) => !!val) as Card['id'][][];

    if (copyClusterIds.length <= 0) {
      return sliced;
    }

    return [...sliced, ..._chunk(copyClusterIds, CHUNK_LAST)];
  }, [clusterIds, gigaEnabled, humanCenteredStrategyValue]);

  return (
    <div className={s.root}>
      <FirstLevel clusterIds={slicedClusters[0]} puids={puids} />
      <div className={s.bottomBlock}>
        <RamblerCalendar />
        <Ad name={BANNERS['100x70']} puids={puids} withoutMarginBottom />
      </div>
      {slicedClusters.slice(1).map((clusterIds, index) => (
        <SecondLevel
          key={`level_${index}`}
          clusterIds={clusterIds}
          injectElements={
            index >= INJECTORS.length
              ? INJECTORS[INJECTORS.length - 1]
              : INJECTORS[index]
          }
          puids={puids}
          bottomAdName={BANNERS.Superfooter}
          floorIndex={index}
          level={index + 2}
          indexOffset={getGlobalIndex(slicedClusters, index + 1) + 1}
        />
      ))}
    </div>
  );
};

export const BaseRedesign = withErrorBoundary(BaseRedesignComponent);
