import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectRegionClustersIds } from 'common/redux/commonData/regions/selectors';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { fetchFullRecsData } from 'common/redux/pages/region/asyncs';
import { selectRegionPageItem } from 'common/redux/pages/region/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { useFetchRecsData } from 'desktop/hooks/useFetchRecsData';

/**
 * Компонент с логикой подрузки рекомендов для страницы региона.
 */
const RegionRecommendsComponent = function RegionRecommends() {
  const projectId = useSelector(selectProjectId);
  const region = useSelector(selectRegionPageItem, shallowEqual);
  const regionClustersIds = useSelector(
    selectRegionClustersIds(region?.id),
    shallowEqual,
  );
  // Рекомендательные кластера из колонки
  const topProjectWidgetsClusterIds = useSelector(
    selectWidgetsTopProjectClusterIDs(projectId),
    shallowEqual,
  );
  const topMainWidgetsClustersIds = useSelector(
    selectWidgetsTopMainClusterIDs,
    shallowEqual,
  );

  const excludedClustersArray = useMemo(
    () => [
      regionClustersIds,
      topProjectWidgetsClusterIds,
      topMainWidgetsClustersIds,
    ],
    [regionClustersIds, topProjectWidgetsClusterIds, topMainWidgetsClustersIds],
  );

  useFetchRecsData({
    fetchRecsData: fetchFullRecsData,
    excludedClustersArray,
    rcmBlockType: RCM_BLOCK_TYPE.regionDesktop,
  });

  return null;
};

export const RegionRecommends = withErrorBoundary(RegionRecommendsComponent);
