import { APP_TYPE } from 'config/common/devices';
import { PAGE_TYPE } from 'config/common/router/typings';

export enum IMAGE_TYPES {
  main = 'main',
  listing = 'listing',
  longread = 'longread',
  cluster = 'cluster',
  format = 'format',
}

/**
 * У некоторых страниц размеры кропа главных картинок совпадают,
 * потому решил разбить их на группы {main, listing ...}
 */
export const PAGE_IMAGE_TYPE: Partial<Record<PAGE_TYPE, IMAGE_TYPES>> = {
  [PAGE_TYPE.topic]: IMAGE_TYPES.main,
  [PAGE_TYPE.home]: IMAGE_TYPES.main,
  [PAGE_TYPE.region]: IMAGE_TYPES.main,

  [PAGE_TYPE.latest]: IMAGE_TYPES.listing,
  [PAGE_TYPE.date]: IMAGE_TYPES.listing,
  [PAGE_TYPE.autotag]: IMAGE_TYPES.listing,

  [PAGE_TYPE.longread]: IMAGE_TYPES.longread,

  [PAGE_TYPE.cluster]: IMAGE_TYPES.cluster,
} as const;

/**
 * Конфиг для прелоада основных картинок на различных страницах
 *
 * Прелоад нужно вставлять как можно раньше, иначе от него не будет толка.
 * Поэтому вставляем в Head-компоненты страниц.
 * Но в момент рендера Head-компонента еще не извесны размеры кропа, поэтому нужен конфиг.
 * Если вставлять прелоад в тот момент когда уже вычисленны размеры кропа,
 * а это обычно в местах где рендярятся эти картинки, то смысла в прелоаде нет
 *
 * Используются массивы размеров, потому что на десктопе есть еще и компактная версия
 * Проблема: при рендере на сервере всегда будет дефолтная широкая версия,
 * а при рендере на клиенте уже получится компактная и от прелоада уже опять нет смысла.
 * Поэтому прелоадим сразу обе версии
 */
export const IMAGES_SIZES_DIR = {
  [IMAGE_TYPES.main]: {
    [APP_TYPE.desktop]: [
      {
        width: 630,
        height: 350,
      },
      {
        width: 430,
        height: 320,
      },
    ],
    [APP_TYPE.mobile]: [
      {
        width: 640,
        height: 360,
      },
    ],
  },

  [IMAGE_TYPES.cluster]: {
    [APP_TYPE.desktop]: [
      {
        width: 630,
        height: 355,
      },
      {
        width: 560,
        height: 315,
      },
    ],
    [APP_TYPE.mobile]: [
      {
        width: 640,
        height: 420,
      },
    ],
  },

  [IMAGE_TYPES.longread]: {
    [APP_TYPE.desktop]: [
      {
        width: 985,
        height: 550,
      },
    ],
  },

  [IMAGE_TYPES.format]: {
    [APP_TYPE.desktop]: [
      {
        width: 430,
        height: 320,
      },
      {
        width: 630,
        height: 350,
      },
    ],
    [APP_TYPE.mobile]: [
      {
        width: 640,
        height: 360,
      },
    ],
  },
  [IMAGE_TYPES.listing]: {
    [APP_TYPE.desktop]: [
      {
        width: 430,
        height: 320,
      },
      {
        width: 630,
        height: 350,
      },
    ],
    [APP_TYPE.mobile]: [
      {
        width: 200,
        height: 180,
      },
    ],
  },
};
