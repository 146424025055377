import React, { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import { RamblerGigaChat } from 'common/components/RamblerComponents/RamblerGigaChat';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectGigaChatData } from 'common/redux/commonData/gigaChat';
import {
  selectCurrentServerTime,
  selectIsMobile,
  selectRuntime,
} from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

type ReachGoalGigaChatWrapperPropsType = {
  className?: string;
};

// 18 сек
const GIGACHAT_IN_VIEW_TIMER = 18;

/**
 * Компонент-обертка для отправки целей в ЯМ для гигачата
 * @param props - пропсы
 * @param props.className – стили блока.
 */
const ReachGoalGigaChatWrapperComponent = function ReachGoalGigaChatWrapper({
  className,
}: ReachGoalGigaChatWrapperPropsType) {
  const runtime = useAppSelector(selectRuntime, shallowEqual);
  const gigaChatData = useAppSelector(selectGigaChatData, shallowEqual);
  const currentTime = useAppSelector(selectCurrentServerTime);
  const isMobile = useAppSelector(selectIsMobile);

  const handleRead = useCallback(() => {
    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_read');
  }, [runtime]);

  const handleShow = useCallback(() => {
    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_show');
  }, [runtime]);

  const handleClick = useCallback(() => {
    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'gigachat_link_click');
  }, [runtime]);

  if (!gigaChatData) return null;

  return (
    <RamblerGigaChat
      data={gigaChatData}
      currentTime={currentTime}
      readingDuration={GIGACHAT_IN_VIEW_TIMER}
      isMobile={isMobile}
      onShow={handleShow}
      onClick={handleClick}
      onRead={handleRead}
      className={className}
    />
  );
};

export const ReachGoalGigaChatWrapper = withErrorBoundary(
  ReachGoalGigaChatWrapperComponent,
);
