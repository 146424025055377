import cn from 'classnames';
import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { GREEN_PROJECT_IDS } from 'common/routes/home/constants';
import { BANNERS } from 'config/common/banners/typings';
import { CardSwitcher } from 'desktop/components/Card';
import { CARD_TYPES } from 'desktop/components/Card/constants';
import { TopicNewsWidget } from 'desktop/components/TopicNewsWidget/components/TopicNewsWidget';
import { useAppSelector } from 'store/hooks';

import { PROJECT_IDS_BY_PROJECT } from '../BaseRedesignOneColumn/constants';
import { ProjectNewsWidget } from '../TopicNewsWidget/components/ProjectNewsWidget';

import { WIDGETS, WIDGETS_VALUES } from './constants';
import { LevelContextProvider } from './context';

import s from './styles.module.css';

const NATIVE_INDEX = 4;

const NATIVE_STYLES = { marginBottom: '20px' };

type LevelLayoutPropsType = {
  clusterIds: Card['id'][];
  topic?: string;
  bannerName: BANNERS;
  puids: PuidsType;
  widgetName?: WIDGETS_VALUES;
  isCompact?: boolean;
  level: number;
  excludedIds?: Card['id'][];
  indexOffset: number;
};

/**
 * Шаблон страницы списка новостей.
 * @param props - пропсы
 * @param props.clusterIds - id кластеров;
 * @param props.topic - рубрика, отображаемая в виджете "Последние новости";
 * @param props.bannerName - название нативного баннера, находящегося среди кластеров;
 * @param props.puids – рекламные пуиды;
 * @param props.widgetName - название виджета;
 * @param props.isCompact - флаг, что отображается компактная версия страницы;
 * @param props.level - уровень по счету;
 * @param props.excludedIds - исключенные из выдачи кластера;
 * @param props.indexOffset - количество индексов, предшевствующих текущему кластеру.
 */
const LevelLayoutComponent = function LevelLayout({
  clusterIds,
  topic,
  bannerName,
  puids,
  widgetName,
  isCompact = false,
  level,
  excludedIds,
  indexOffset,
}: LevelLayoutPropsType) {
  const projectId = useAppSelector(selectProjectId);

  const content = clusterIds.map((clusterId, index) => {
    const item = (
      <CardSwitcher
        key={clusterId}
        clusterId={clusterId}
        type={CARD_TYPES.LIST}
        index={index + indexOffset}
      />
    );

    if (index === NATIVE_INDEX) {
      return [
        <Ad
          key={`${clusterId}-${bannerName}`}
          name={bannerName}
          puids={puids}
          className={cn(
            s.ad,
            s.adPlaceholder,
            'banner--native-context--redesign_list_new',
          )}
          loadedStyle={NATIVE_STYLES}
        />,
        item,
      ];
    }

    return item;
  });

  return (
    <LevelContextProvider value={level}>
      <ReachGoalLevelWrapper level={level} className={s.root}>
        <div className={s.mainBlock}>
          {content}
          {isCompact && !!widgetName && WIDGETS[widgetName]}
        </div>
        {!isCompact && (
          <div>
            <div className={s.middleBlock}>
              {GREEN_PROJECT_IDS.includes(projectId)
                ? level > 0 &&
                  PROJECT_IDS_BY_PROJECT[projectId]?.[level - 1] && (
                    <ProjectNewsWidget
                      projectId={PROJECT_IDS_BY_PROJECT[projectId]?.[level - 1]}
                      excludedIds={excludedIds}
                    />
                  )
                : !!topic && (
                    <TopicNewsWidget
                      topicAlias={topic}
                      excludedIds={excludedIds}
                    />
                  )}
              {!!widgetName && WIDGETS[widgetName]}
            </div>
          </div>
        )}
        <div className={s.columnWrapper}>
          <Ad
            name={BANNERS['240x400_2']}
            puids={puids}
            isSticky
            withoutMarginBottom
          />
        </div>
      </ReachGoalLevelWrapper>
    </LevelContextProvider>
  );
};

export const LevelLayout = memo(
  LevelLayoutComponent,
  (prev, next) =>
    _isequal(prev.clusterIds, next.clusterIds) &&
    prev.isCompact === next.isCompact,
);
