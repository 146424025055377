import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Head } from 'common/components/headers/Head';
import { PreloadMainImage } from 'common/components/headers/PreloadMainImage';
import { ItemListSchema } from 'common/components/schemaOrg/ItemList';
import { WebSiteSchema } from 'common/components/schemaOrg/WebSite';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectHomePageClusterInCardData } from 'common/redux/pages/home/selectors';
import {
  selectProjectDetails,
  selectIsMobile,
} from 'common/redux/runtime/selectors';
import { useHeader } from 'desktop/hooks/useHeader';

/**
 * Мета домашней страницы.
 */
const HomeHeaderComponent = function HomeHeader() {
  const isMobile = useSelector(selectIsMobile);
  const projectDetails = useSelector(selectProjectDetails, shallowEqual);

  const { clustersBySchema } = useHeader(selectHomePageClusterInCardData);

  return (
    <>
      <PreloadMainImage image={clustersBySchema[0]?.image} />
      <Head
        top100PageType="main"
        pageTitle={projectDetails?.title}
        pageDescription={projectDetails?.description}
        pageKeywords={projectDetails?.keywords}
        pageUrl="/"
        rssUrl="/rss/head/?limit=100"
        isMobile={isMobile}
        data-test="head"
      />
      <ItemListSchema items={clustersBySchema} />
      <WebSiteSchema />
    </>
  );
};

export const HomeHeader = withErrorBoundary(HomeHeaderComponent);
