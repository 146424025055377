import React, { useRef, useState, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  RamblerPlayerAutoPlay,
  useLoadRamblerPlayer,
} from 'common/components/VideoPlayer/hooks/useLoadRamblerPlayer';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectHomePagePuids } from 'common/redux/pages/home/selectors';
import { PlayRedesignIcon } from 'icons';

import s from './styles.module.css';

type IntegratedVideoPlayerPropsType = {
  recordId: Card['video']['recordId'];
  isLive: Card['video']['isLive'];
  style?: StylesType;
  clusterUrl: string;
};

/**
 * Видеоплеер, скомбинированный с кнопкой запуска.
 * @param props - пропсы
 * @param props.recordId - id видео в видеоплатформе;
 * @param props.isLive - флаг, что это трансляция;
 * @param props.style - набор стилей для родительского компонента и дочернего в том числе;
 * @param props.clusterUrl - url кластера.
 */
const IntegratedVideoPlayerComponent = function IntegratedVideoPlayer({
  recordId,
  isLive,
  style,
  clusterUrl,
}: IntegratedVideoPlayerPropsType) {
  const puids = useSelector(selectHomePagePuids, shallowEqual);

  const playerRef = useRef<HTMLDivElement | null>(null);

  const [isPlayClicked, setIsPlayClicked] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setIsPlayClicked(true);
    },
    [],
  );

  useLoadRamblerPlayer({
    clusterUrl,
    recordId,
    playerRef,
    sspJparams: puids,
    shouldDrawPlayer: !!recordId && isPlayClicked,
    isLive,
    height: playerRef.current?.parentElement?.clientHeight,
    autoPlay: RamblerPlayerAutoPlay.ON,
  });

  return (
    <>
      {!isPlayClicked && (
        <button type="button" onClick={handleClick} className={s.button}>
          <PlayRedesignIcon className={style?.icon} />
        </button>
      )}
      <div ref={playerRef} className={style?.preview} />
    </>
  );
};

export const IntegratedVideoPlayer = withErrorBoundary(
  IntegratedVideoPlayerComponent,
);
