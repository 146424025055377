import React from 'react';
import { WebSite } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { useItemForWebSite } from './hooks';

/**
 * Разметка WebSite с SearchAction
 * https://developers.google.com/search/docs/data-types/sitelinks-searchbox
 *
 */
const WebSiteSchemaComponent = function WebSiteSchema() {
  const item = useItemForWebSite();

  return <JsonLd<WebSite> item={item} />;
};

export const WebSiteSchema = withErrorBoundary(WebSiteSchemaComponent);
