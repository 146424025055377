import cn from 'classnames';
import React, { memo } from 'react';

import { CommentsDirectorIcon, CommentsRoundedIcon, DialogIcon } from 'icons';
import { SVGElementType } from 'icons/typings';

import s from './styles.module.css';

const COMMENTS_COUNTER_ICONS: { [key: string]: SVGElementType } = {
  'icon-comments': CommentsDirectorIcon,
  'icon-comments-rounded': CommentsRoundedIcon,
  'icon-dialog': DialogIcon,
};

type IconIdType = keyof typeof COMMENTS_COUNTER_ICONS;

type CommentsCounterPropsType = {
  count: number | undefined;
  className?: string | null;
  iconId?: IconIdType;
  showIcon?: boolean;
};

/**
 * @see cluster_redesign__dir1
 *
 * Счетчик комментов
 * @param props - пропсы
 * @param props.count - количество комментов;
 * @param props.className - стиль для обертки;
 * @param props.iconId - id картинки для иконки;
 * @param props.showIcon - флаг, нужна ли иконка.
 */
const CommentsCounterComponent = function CommentsCounter({
  count,
  className = null,
  iconId = 'icon-comments',
  showIcon = false,
}: CommentsCounterPropsType) {
  const ComponentIcon = iconId ? COMMENTS_COUNTER_ICONS[iconId] : null;

  return (
    (Number(count) > 0 || showIcon) && (
      <span className={cn(s.root, className)}>
        {ComponentIcon && <ComponentIcon className={s.icon} />}
        {Number(count) > 0 && <span>{count}</span>}
      </span>
    )
  );
};

export const CommentsCounter = memo(CommentsCounterComponent);
