import React, { memo } from 'react';

import { RnetWidgetSkeleton } from 'desktop/components/RnetWidgetSkeleton';
import { TopWidgetSkeleton } from 'desktop/components/TopNewsWidgets/TopWidgetSkeleton';

import { WIDGET_TYPE } from '../constants';

import s from './styles.module.css';

type SkeletonsPropsType = {
  arraySkeletons: string[];
};

/**
 * Скелетон для виджетов
 * @param props - пропс
 * @param props.arraySkeletons - массив скелетонов.
 */
const SkeletonsComponent = function Skeletons({
  arraySkeletons,
}: SkeletonsPropsType) {
  return (
    <div className={s.skeletons}>
      {arraySkeletons.map((skeleton: string) => (
        <div key={Math.random()} className={s.skeleton}>
          {skeleton === WIDGET_TYPE.top && <TopWidgetSkeleton />}
          {skeleton === WIDGET_TYPE.rnet && <RnetWidgetSkeleton />}
        </div>
      ))}
    </div>
  );
};

export const Skeletons = memo(SkeletonsComponent);
