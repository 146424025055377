import React from 'react';

import { RamblerRecommendationBlock } from 'common/components/RamblerComponents/RamblerRecommendationBlock';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectRecommendBlockID } from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { useAppSelector } from 'store/hooks';
import { adaptClustersToRcmBlockItems } from 'utils/adapters';

import { NewsWidgetSkeleton } from '../NewsWidgetSkeleton';
import { useRecommendedNewsWidget } from '../TopNewsWidgets/hooks/useRecommendedNewsWidget';

type RecommendationWidgetPropsType = {
  clusterId: Card['id'];
  rcmKey: RCM_BLOCK_TYPE;
  title?: string;
  withImage?: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  onClick?: () => void;
  isFirstRcmWidget?: boolean;
};

/**
 * Виджет рекоммендуемых новостей.
 * @param props - пропсы
 * @param props.clusterId - id кластера;
 * @param props.rcmKey - id блока рекомендаций;
 * @param props.title - заголовок виджета;
 * @param props.withImage - флаг, что нужна картинка для первой новости;
 * @param props.containerRef - реф для контейнера виджета, чтобы получать высоту выше;
 * @param props.onClick - функция клика по новости, используется для отправки в ЯМ;
 * @param props.isFirstRcmWidget - флаг, что этот виджет рендерится первым на странице, для корректной работы на странице должен быть хотя бы один виджет с данным пропсом.
 */
const RecommendationWidgetComponent = function RecommendationWidget({
  clusterId,
  rcmKey,
  title = 'Рекомендуем',
  withImage = false,
  containerRef,
  onClick,
  isFirstRcmWidget = false,
}: RecommendationWidgetPropsType) {
  const blockId = useAppSelector(selectRecommendBlockID(rcmKey));

  const { clusters } = useRecommendedNewsWidget(clusterId, isFirstRcmWidget);

  const items = adaptClustersToRcmBlockItems({
    clusters,
    blockId,
    contextItemId: rcmKey,
  });

  if (!items.length) {
    return <NewsWidgetSkeleton title={title} withImage={withImage} />;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div ref={containerRef} onClick={onClick}>
      <RamblerRecommendationBlock
        title={title}
        projectType="media"
        items={items}
        withImage={withImage}
      />
    </div>
  );
};

export const RecommendationWidget = withErrorBoundary(
  RecommendationWidgetComponent,
);
