/**
 * Простановка неразрывных пробелов в словах с проверкой на короткие слова
 * @param text - исходная строка
 * @param wordLength - значение длины для слова для добавления неразрывного пробела
 */
export const addNonBreakingSpaces = (text: string, wordLength: number = 3) => {
  if (!text) return '';
  const arr = text.split(' ');

  const result = arr.map((word, index) => {
    if (index === arr.length - 1) {
      return word;
    }

    if (word.length <= wordLength) {
      return `${word}\u00A0`;
    }

    return `${word} `;
  });

  return result.join('');
};
