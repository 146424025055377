import React, { memo, MutableRefObject, useCallback } from 'react';

import { RcmCardType, RecEvents } from 'common/RecEvents';

import { CardLayout, CardPropsType } from '../Base';

type RecLayoutPropsType = CardPropsType & {
  rcmKey: RcmCardType['rcmKey'];
  position: RcmCardType['position'];
  contextItemId: RcmCardType['contextItemId'];
};

/**
 * Обертка над обычной карточкой, добавляющей отслеживание и отправку событий для рекомендаций.
 * @param props - пропсы
 * @param props.clusterUrl - url кластера;
 * @param props.recordId - id видео в видеоплатформе;
 * @param props.isLive - флаг, что идет видеотрансляция;
 * @param props.imageSrc - url картинки;
 * @param props.imageParams - Объект с информацией по картинке;
 * @param props.title - Заголовок новости;
 * @param props.date - Дата в продовом формате "n минут/секунд/часов назад";
 * @param props.topicName - Название рубрики отображаемого кластера;
 * @param props.commentsCount - Количество комментов на новости;
 * @param props.noComments - флаг, что комменты отключены;
 * @param props.style - Стили. Все стили приходят снаружи, сам компонент своих стилей не имеет;
 * @param props.videoDuration - длительность видео;
 * @param props.top100 - Индекс карточки для топ100;
 * @param props.rcmKey — ключ, по которому храним blockID в конфигурации (topNow например);
 * @param props.position — индекс в списке выводимых карточек;
 * @param props.clusterId - id кластера;
 * @param props.contextItemId - идентификатор страницы, на которой используется блок (clusterId, pageName или еще что-то);
 * @param props.isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px).
 */
const RecLayoutComponent = function RecLayout({
  clusterUrl,
  recordId,
  isLive,
  imageSrc,
  imageParams,
  title,
  date,
  topicName,
  style,
  videoDuration,
  commentsCount,
  top100,
  rcmKey,
  position,
  clusterId,
  contextItemId,
  isCompactCard,
  noComments,
}: RecLayoutPropsType) {
  const render = useCallback(
    (ref: MutableRefObject<HTMLAnchorElement>, onClick: () => void) => (
      <CardLayout
        clusterUrl={clusterUrl}
        recordId={recordId}
        isLive={isLive}
        imageSrc={imageSrc}
        imageParams={imageParams}
        title={title}
        date={date}
        topicName={topicName}
        style={style}
        videoDuration={videoDuration}
        commentsCount={commentsCount}
        top100={top100}
        onClick={onClick}
        linkRef={ref}
        isLazy
        isCompactCard={isCompactCard}
        noComments={noComments}
        clusterId={clusterId}
      />
    ),
    [
      clusterUrl,
      commentsCount,
      date,
      imageParams,
      imageSrc,
      isCompactCard,
      isLive,
      noComments,
      recordId,
      style,
      title,
      top100,
      topicName,
      videoDuration,
      clusterId,
    ],
  );

  return (
    <RecEvents
      clusterId={clusterId}
      rcmKey={rcmKey}
      position={position}
      contextItemId={contextItemId}
      // @ts-expect-error: ¯\_(ツ)_/¯
      render={render}
    />
  );
};

export const RecLayout = memo(RecLayoutComponent);
