import React from 'react';
import { useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectProjectId,
  selectSberWidgetErid,
} from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/common/project/typings';
import { PAGE_TYPE } from 'config/common/router/typings';

import { SberWidget } from './components/SberWidget';

const DEFAULT_DISABLED_ERID_VALUE = '0';

type SberWidgetWrapperPropsType = {
  className?: string;
};

/**
 * Обертка над виджетом, отображающая его лишь на определенных страницах
 * @param props - пропсы
 * @param props.className - дополнительный класс.
 */
const SberWidgetWrapperComponent = function SberWidgetWrapper({
  className,
}: SberWidgetWrapperPropsType) {
  const projectId = useSelector(selectProjectId);
  const pageName = useSelector(selectPageName);
  const erid = useSelector(selectSberWidgetErid);

  if (
    projectId !== PROJECT_IDS.Finance ||
    pageName !== PAGE_TYPE.home ||
    erid === DEFAULT_DISABLED_ERID_VALUE
  ) {
    return null;
  }

  return <SberWidget erid={erid} className={className} />;
};

export const SberWidgetWrapper = withErrorBoundary(SberWidgetWrapperComponent);
