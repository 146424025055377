import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import {
  selectTopicPageClusterIds,
  selectTopicPageIsGreen,
} from 'common/redux/pages/topic/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { useFetchRecsData } from 'desktop/hooks/useFetchRecsData';
import { useAppSelector } from 'store/hooks';

/**
 * Компонент с логикой подрузки рекомендов для страницы топиков.
 */
const TopicRecommendsComponent = function TopicRecommends() {
  const projectId = useAppSelector(selectProjectId);
  const topicClustersIds = useAppSelector(
    selectTopicPageClusterIds,
    shallowEqual,
  );
  // Рекомендательные кластера из колонки
  const topProjectWidgetsClusterIds = useAppSelector(
    selectWidgetsTopProjectClusterIDs(projectId),
    shallowEqual,
  );
  const topMainWidgetsClustersIds = useAppSelector(
    selectWidgetsTopMainClusterIDs,
    shallowEqual,
  );
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const isGreenTopic = useAppSelector(selectTopicPageIsGreen);

  const rcmBlockType = isGreenTopic
    ? RCM_BLOCK_TYPE.greenTopicDesktop
    : RCM_BLOCK_TYPE.topicDesktop;

  /**
   * на странице редизайна нет виджета топ новостей поэтому topMainWidgetsClustersIds всегда пуст
   * и от этого checkForFetch всегда возвращает false внутри useFetchRecsData (клластера не подгружаются)
   */
  const excludedClustersArray = useMemo(
    () =>
      forceRedesign
        ? [topicClustersIds, topProjectWidgetsClusterIds]
        : [
          topicClustersIds,
          topProjectWidgetsClusterIds,
          topMainWidgetsClustersIds,
        ],
    [
      forceRedesign,
      topicClustersIds,
      topProjectWidgetsClusterIds,
      topMainWidgetsClustersIds,
    ],
  );

  useFetchRecsData({
    fetchRecsData: fetchFullRecsData,
    excludedClustersArray,
    rcmBlockType,
  });

  return null;
};

export const TopicRecommends = withErrorBoundary(TopicRecommendsComponent);
