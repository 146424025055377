import { FONTS, FONTS_PARAMS } from './config';

/**
 * Хук, выдающий нужные стили со шрифтом для страницы.
 * Если нужный шрифт не загрузился, то стиль должен отреагировать на стили
 * установленные функцией @see loadFontWithTimeout .
 * Необходимо подключение @see loadFontWithTimeout в тело html верстки (для максимально раннего срабатывания).
 * @param font Шрифт из FONTS, который нужно отобразить.
 * @returns Стили для шрифта. Подключаются напрямую в отлаживаемый код.
 */
export const getLoadFont = (font: FONTS) => FONTS_PARAMS[font].fontStyles;

export { FONTS, FONTS_PARAMS };
