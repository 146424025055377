import cn from 'classnames';
import React from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';

import s from './styles.module.css';

type CardItemPropsType = {
  index: number;
  url: Card['url'];
  title: Card['title'];
  commentsCount: Card['commentsCount'];
  onClick?: () => void;
};

/**
 * Карточка новости для виджета новостей по топику
 * @param props - пропсы
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.commentsCount - количество комментов;
 * @param props.index - индекс карточки для топ100;
 * @param props.onClick - функция клика по карточке.
 */
const CardItemComponent = function CardItem({
  index,
  url,
  title,
  commentsCount,
  onClick,
}: CardItemPropsType) {
  const top100AttributeWithValue = useTop100AttributeWithValue(
    `item::${index + 1}`,
  );

  const splitedTitle = title.split(' ');
  const joinedTitle = splitedTitle.slice(0, -1).join(' ');
  const lastWord = splitedTitle.slice(-1);

  return (
    <li className={s.item}>
      <a
        className={cn(s.link, s.withCircle)}
        href={url}
        onClick={onClick}
        {...top100AttributeWithValue}
      >
        {joinedTitle}

        {/* Чтобы иконка комментов не висела одиноко на строке
                &nbsp; и &#160; не сработали, поэтому через вырезание последнего слова */}
        <span className={s.nowrap}>
          {' '}
          {lastWord}
          <CommentsCounter
            count={commentsCount}
            iconId="icon-comments-rounded"
            className={s.comments}
          />
        </span>
      </a>
    </li>
  );
};

export const CardItem = withErrorBoundary(CardItemComponent);
