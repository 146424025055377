import { s3FitType } from 'common/utils/s3';

/**
 * Без понятия зачем нужна эта мапка но, вероятнее всего, её будет целесообразно спилить после полного переезда на s3.
 */

/**
 * Дока по вспомогательному API Rstorage https://rstorage.pages.rambler-co.ru/rstorage-docs/
 */

export const JPEG = 'jpeg';

export const JPEG_COMP_50 = 'jpeg:comp:50,prog:true';

export const JPEG_NO_COMP = 'jpeg:no-comp';

export const AUTO = 'auto';

export const WEBP = 'webp';

export const PNG_COMP_3 = 'png:comp:3';

export const PNG_NO_COMP = 'png:no-comp';

export const PNG_MAX_COMP = 'png:max-comp';

export const PNG_MIN_COMP = 'png:min-comp';

export const GIF = 'gif';

export const GIF_PROG_FALSE = 'gif:prog:false';

export const GIF_PROG_TRUE = 'gif:prog:true';

// original format

export const RETAIN = 'retain';

export const S3_FIT = s3FitType.clip;
