import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

/**
 * Компонент скелетон для новостей в фиде
 */
const SkeletonNewsComponent = function SkeletonNews() {
  return (
    <div className={s.container}>
      <div className={s.avatar} />
      <div className={s.textGroup}>
        <div className={s.text} />
        <div className={cn(s.text, s.text2)} />
        <div className={cn(s.text, s.text3)} />
      </div>
    </div>
  );
};

export const SkeletonNews = withErrorBoundary(SkeletonNewsComponent);
