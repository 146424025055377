import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

type NewsWidgetSkeletonPropsType = {
  title: string;
  withImage?: boolean;
  newsCount?: number;
};

/**
 * Компонент скелетона для виджетов новостей
 * @param props - пропсы
 * @param props.title - заголовок виджета;
 * @param props.withImage - флаг что первый кластер с картинкой;
 * @param props.newsCount - кол-во новостей в виджете.
 */
const NewsWidgetSkeletonComponent = function NewsWidgetSkeleton({
  title,
  withImage,
  newsCount = 6,
}: NewsWidgetSkeletonPropsType) {
  return (
    <div className={s.root}>
      <div className={s.title}>{title}</div>

      <div className={s.items}>
        {Array.from(new Array(newsCount)).map((_item, index) => (
          <div key={index} className={s.item}>
            {index === 0 && withImage && <div className={s.image} />}
            <div className={s.text} />
            <div className={cn(s.text, s.text2)} />
            <div className={cn(s.text, s.text3)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const NewsWidgetSkeleton = withErrorBoundary(
  NewsWidgetSkeletonComponent,
);
