import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import s from './styles.module.css';

/**
 * Компонент скелетон для виджета топа
 */
const TopWidgetSkeletonComponent = function TopWidgetSkeleton() {
  return (
    <div className={s.textGroup}>
      <div className={s.text} />
      <div className={s.text} />
      <div className={cn(s.text, s.text3)} />
    </div>
  );
};

export const TopWidgetSkeleton = withErrorBoundary(TopWidgetSkeletonComponent);
