import cn from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';

import { RamblerLoader } from 'common/components/RamblerComponents/RamblerLoader';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { Skeletons } from 'desktop/components/FeedWidgetLayout/Skeletons';

import {
  WIDGET_TYPE,
  SAVING_TOP_LIMIT,
  SAVING_RNET_LIMIT,
  SAVING_DEFAULT_LIMIT,
} from '../constants';
import { ErrorMessage } from '../ErrorMessage';
import s from '../styles.module.css';

/**
 * Заполнение массива скелетонов
 * @param length – длина массива
 * @param widgetType – тип виджета
 */
const fillArray = (length: number, widgetType: string) =>
  Array(length).fill(widgetType);

/**
 * Получение массива скелетонов
 * @param widgetType – тип виджета
 */
const getArraySkeletons = (widgetType: string | undefined) => {
  if (widgetType === WIDGET_TYPE.top) {
    return fillArray(SAVING_TOP_LIMIT, widgetType);
  }

  if (widgetType === WIDGET_TYPE.rnet) {
    return fillArray(SAVING_RNET_LIMIT, widgetType);
  }

  return fillArray(SAVING_DEFAULT_LIMIT, '');
};

type WidgetContentPropsType = {
  styles?: StylesType;
  onRefresh?: () => void;
  widgetType?: string;
  isError?: boolean;
  shouldWrapInUL?: boolean;
};

/**
 * Содержимое виджета: кластер или список кластеров,
 * в зависимости от стадии загрузки, лоадер или скелетон, или ошибка - в случае ошибки.
 * @param props - пропсы
 * @param props.children - дочерний элемент;
 * @param props.styles - стили для компонента;
 * @param props.onRefresh - функция для перезагрузки виджета;
 * @param props.widgetType – тип виджета;
 * @param props.isError - флаг ошибки загрузки новостей;
 * @param props.shouldWrapInUL - флаг, что надо оборачивать контент в отдельный контейнер списка.
 */
const WidgetContentComponent = function WidgetContent({
  children,
  isError,
  shouldWrapInUL,
  widgetType,
  styles,
  onRefresh,
}: PropsWithChildren<WidgetContentPropsType>) {
  const childrenCount = React.Children.count(children);
  const arraySkeletons = getArraySkeletons(widgetType);

  const loader =
    widgetType === WIDGET_TYPE.top || widgetType === WIDGET_TYPE.rnet ? (
      <Skeletons arraySkeletons={arraySkeletons} />
    ) : (
      <RamblerLoader className={cn(s.loader, styles?.loader)} />
    );

  const content =
    shouldWrapInUL && childrenCount > 1 ? (
      <ul className={s.content}>{children}</ul>
    ) : (
      (children as ReactElement)
    );

  if (childrenCount) return content;

  if (isError) return <ErrorMessage onRefresh={onRefresh} />;

  return loader;
};

export const WidgetContent = withErrorBoundary(WidgetContentComponent);
