import React from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { RamblerImageBg } from 'common/components/RamblerComponents/RamblerImage';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

const IMAGE_HEIGHT = 80;

type CardItemPropsType = {
  index: number;
  publicationTime: Card['publicationTime'];
  url: Card['url'];
  imageUrl: Card['image']['url'];
  imageS3: Card['image']['s3'];
  title: Card['title'];
  commentsCount: Card['commentsCount'];
  onClick?: () => void;
};

/**
 * Карточка новости для виджета новостей по топику
 * @param props - пропсы
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.imageUrl - ссылка на источник картинки кластера;
 * @param props.imageS3 - флаг, что оригинал загружен в rstorage;
 * @param props.commentsCount - количество комментов;
 * @param props.publicationTime - время публикации;
 * @param props.index - индекс карточки для топ100;
 * @param props.onClick - индекс карточки для топ100.
 */
const CardItemComponent = function CardItem({
  index,
  publicationTime,
  url,
  imageUrl,
  imageS3,
  title,
  commentsCount,
  onClick,
}: CardItemPropsType) {
  const top100AttributeWithValue = useTop100AttributeWithValue(
    `item::${index + 1}`,
  );

  return (
    <li>
      <a
        className={s.item}
        href={url}
        onClick={onClick}
        {...top100AttributeWithValue}
      >
        <RamblerImageBg
          height={IMAGE_HEIGHT}
          src={imageUrl}
          className={s.img}
          isS3={imageS3}
          isLazy
        />
        <div>
          <p className={s.title}>{title}</p>
          <div className={s.info}>
            <p className={s.date}>
              {!!publicationTime &&
                datesince({
                  pubdate: new Date(publicationTime).getTime() / 1000,
                })}
            </p>
            <CommentsCounter count={commentsCount} className={s.comments} />
          </div>
        </div>
      </a>
    </li>
  );
};

export const CardItem = withErrorBoundary(CardItemComponent);
