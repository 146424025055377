import React, { useId } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useCounterId } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-emotional-horoscope-container';
const WIDGET_ID = 'rambler-emotional-horoscope';

/**
 * Компонент с ембедом Рамблера - Эмоциональный гороскоп.
 */
const RamblerEmotionalHoroscopeComponent =
  function RamblerEmotionalHoroscope() {
    const id = useId();
    const yandexCounterId = useCounterId(COUNTER_ID.WebMobile);

    const { embedRef, isInited } = useLoadRamblerEmbed({
      parentId: `${PARENT_ID}${id}`,
      widgetId: `${WIDGET_ID}${id}`,
      url: 'https://embeds.rambler.ru/horoscopes/emotional-horoscope.js',
    });

    return (
      <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
        <rambler-emotional-horoscope
          ref={embedRef}
          yandex-metrica-id={yandexCounterId}
        />
      </div>
    );
  };

export const RamblerEmotionalHoroscope = withErrorBoundary(
  RamblerEmotionalHoroscopeComponent,
);
