import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchVideoForWidget } from 'common/redux/commonData/widgets/videoWidget/asyncs';
import {
  selectWidgetsVideoClusterWithOptionalClusterId,
  selectWidgetsVideoError,
  selectWidgetsVideoLoadingState,
} from 'common/redux/commonData/widgets/videoWidget/selectors';
import { useAppDispatch } from 'store/hooks';

type UseInitVideoWidgetType = (
  // id видеокластера, если уже есть. Иначе id будет извлечено самостоятельно.
  clusterId?: Card['id'] | null,
) => {
  // Кластер с данными о видео.
  videoCluster: Card;
  // Флаг, что данные для виджета в процессе загрузки
  isLoading: boolean;
  // Сообщение ошибки загрузки
  error?: string;
};

/**
 * Хук для инициализации данных видео-виджета.
 * @param clusterId - id кластера
 */
export const useInitVideoWidget: UseInitVideoWidgetType = (clusterId) => {
  const dispatch = useAppDispatch();

  const videoCluster = useSelector(
    selectWidgetsVideoClusterWithOptionalClusterId(clusterId),
    shallowEqual,
  );
  const isLoading = useSelector(selectWidgetsVideoLoadingState);
  const error = useSelector(selectWidgetsVideoError);

  useEffect(() => {
    if (!videoCluster && !isLoading && !error) {
      dispatch(fetchVideoForWidget());
    }
  }, [dispatch, videoCluster, isLoading, error]);

  return {
    videoCluster: videoCluster!,
    isLoading,
    error,
  };
};
