import React, { memo } from 'react';

import { RefreshIcon } from 'icons';

import s from './styles.module.css';

type ErrorMessagePropsType = {
  onRefresh?: () => void;
};

/**
 * Компонент сообщения об ошибке при загрузке новостей в виджете
 * @param props - пропсы
 * @param props.onRefresh – функция для загрузки данных в виджете.
 */
const ErrorMessageComponent = function ErrorMessage({
  onRefresh,
}: ErrorMessagePropsType) {
  return (
    <div className={s.root}>
      <p>Ошибка загрузки новостей. {onRefresh && 'Попробуйте еще раз'}</p>

      {onRefresh && (
        <button
          type="button"
          aria-label="Обновить новости"
          onClick={onRefresh}
          className={s.button}
        >
          <RefreshIcon className={s.icon} />
        </button>
      )}
    </div>
  );
};

export const ErrorMessage = memo(ErrorMessageComponent);
