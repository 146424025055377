import React, { memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { CardLayout, RecLayout } from 'common/components/CardLayout';
import { useCardConfig } from 'common/components/CardLayout/context';
import { selectCardById } from 'common/redux/commonData/entries/selectors';
import { selectCurrentServerTime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

const IMAGE_WIDTH = 220;
const IMAGE_HEIGHT = 125;

type ListCardPropsType = {
  clusterId: Card['id'] | null;
  index: number;
  isCompactCard?: boolean;
  onClick?: () => void;
};

/**
 * Функция для удаления часового пояса при передаче его куда-либо.
 * @param datetimeString - строка с датой.
 * @returns строка с датой, без часового пояса.
 */
const normalizeDatetimeString = (datetimeString = '') => {
  if (!datetimeString || typeof datetimeString !== 'string') {
    return datetimeString;
  }

  return datetimeString.replace(/[+-][^T]+$/, '');
};

/**
 * DESKTOP
 *
 * Списочная карточка reDesign-версии
 *
 * Является функциональной обёрткой над CardLayout, достаёт кластер по id, формирует пропсы, и передаёт стили.
 * @param props - пропсы
 * @param props.clusterId - id кластера, используется для поиска его в entries и последующей отрисовки;
 * @param props.index - индекс кластера в глобальном отношении;
 * @param props.isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px);
 * @param props.onClick – функция клика по карточке новости.
 */
export const ListCardComponent = function ListCard({
  clusterId,
  index,
  isCompactCard = false,
  onClick,
}: ListCardPropsType) {
  const cardData = useAppSelector(selectCardById(clusterId), shallowEqual);
  const currentServerTime = useAppSelector(selectCurrentServerTime);

  const { getTop100, getRecBlockType } = useCardConfig();

  const imageParams = useMemo(
    () => ({
      s3: cardData?.image?.s3,
      width: IMAGE_WIDTH,
      height: IMAGE_HEIGHT,
    }),
    [cardData?.image?.s3],
  );

  if (!cardData) return null;

  const {
    title,
    image: { url: imageUrl },
    url,
    commentsCount,
    topic,
    publicationTime,
    video: { duration },
    noComments,
  } = cardData;

  const blockInitAtd = getRecBlockType({ index });
  const top100Value = getTop100({ card: cardData, index });

  const formattedDate = publicationTime
    ? datesince({
        pubdate:
          new Date(normalizeDatetimeString(publicationTime)).getTime() / 1000,
        date: new Date(currentServerTime),
      })
    : undefined;

  if (blockInitAtd) {
    return (
      <RecLayout
        title={title}
        imageSrc={imageUrl}
        imageParams={imageParams}
        clusterUrl={url}
        commentsCount={commentsCount}
        topicName={topic}
        date={formattedDate}
        style={s}
        top100={top100Value}
        clusterId={clusterId}
        position={index}
        rcmKey={blockInitAtd}
        contextItemId={blockInitAtd}
        isCompactCard={isCompactCard}
        noComments={noComments}
        onClick={onClick}
      />
    );
  }

  return (
    <CardLayout
      title={title}
      imageSrc={imageUrl}
      imageParams={imageParams}
      clusterUrl={url}
      commentsCount={commentsCount}
      topicName={topic}
      date={formattedDate}
      style={s}
      videoDuration={duration}
      top100={top100Value}
      isCompactCard={isCompactCard}
      noComments={noComments}
      onClick={onClick}
      clusterId={clusterId}
    />
  );
};

export const ListCard = memo(ListCardComponent);
