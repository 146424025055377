import { addNonBreakingSpaces } from 'utils/nonBreakingSpaces';

type GetStringPropsType = {
  title: string | undefined;
  clustersBySchema: (Card | Cluster)[];
};

/**
 * Получение описания для меты страницы.
 * @param props - пропсы
 * @param props.title - название страницы;
 * @param props.clustersBySchema - кластера страницы.
 */
export const getDescriptionString = ({
  title,
  clustersBySchema,
}: GetStringPropsType) => {
  const [firstCluster = null, secondCluster = null] = clustersBySchema.slice(
    0,
    2,
  );

  return firstCluster?.title && secondCluster?.title
    ? `${title || ''} - ${addNonBreakingSpaces(
        firstCluster.title,
      )}, ${addNonBreakingSpaces(secondCluster.title)}`
    : '';
};
