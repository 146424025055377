import cn from 'classnames';
import React, { ReactNode, useCallback, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useObserver } from 'common/hooks/useObserver';
import { selectPageName } from 'common/redux/appController';
import { selectRuntime } from 'common/redux/runtime/selectors';
import { useAppSelector } from 'store/hooks';
import { yaReachGoal } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { REACH_GOAL_REDESIGN_PAGES } from '../config';

type ReachGoalLevelWrapperTypeProps = {
  level: number;
  children: ReactNode;
  isMobile?: boolean;
  className?: string;
};

const OBSERVER_CONFIG = { threshold: 0.2 };

/**
 * Компонент-обертка этажа для отправки целей в ЯМ на странице вертикали или рубрики
 * @param props - пропсы
 * @param props.children - дочерний компонент;
 * @param props.level – номер этажа;
 * @param props.isMobile – флаг мобильной версии, на мобильной версии нет этажей по коду поэтому клики навешиваются отдельно на карточки;
 * @param props.className – стили для блока.
 */
const ReachGoalLevelWrapperComponent = function ReachGoalLevelWrapper({
  children,
  level,
  className,
  isMobile,
}: ReachGoalLevelWrapperTypeProps) {
  const runtime = useAppSelector(selectRuntime, shallowEqual);
  const pageName = useAppSelector(selectPageName);
  const [wasView, setWasView] = useState(false);

  // в момент редизайна сравниваем только рубрики и вертикали, поэтому отключаем отправку целей для регионов и пр.
  const allowShowReachGoal =
    REACH_GOAL_REDESIGN_PAGES.includes(pageName) && !wasView;

  const onLevelView = useCallback(() => {
    if (level === 1) {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, '1stage_editor_top_show');
      setWasView(true);

      return;
    }

    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'news_show_Nstage', {
      stages: level,
    });
    setWasView(true);
  }, [level, runtime]);

  const levelRef = useObserver<HTMLDivElement>({
    callback: allowShowReachGoal ? onLevelView : null,
    observerConfig: OBSERVER_CONFIG,
  });

  const onLevelClick = () => {
    if (!REACH_GOAL_REDESIGN_PAGES.includes(pageName)) return;

    if (level === 1) {
      yaReachGoal(runtime, COUNTER_ID.WebMobile, '1stage_editor_top_click');

      return;
    }

    yaReachGoal(runtime, COUNTER_ID.WebMobile, 'news_click_Nstage', {
      stages: level,
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={cn(className)}
      ref={levelRef}
      {...(!isMobile && { onClick: onLevelClick })}
    >
      {children}
    </div>
  );
};

export const ReachGoalLevelWrapper = withErrorBoundary(
  ReachGoalLevelWrapperComponent,
);
