import React, { useId } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useCounterId } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-mortgage-calculator-container';
const WIDGET_ID = 'rambler-mortgage-calculator';

/**
 * Компонент с ембедом Рамблера - Ипотечный калькулятор.
 */
export const RamblerMortgageCalculator = withErrorBoundary(
  function RamblerMortgageCalculator() {
    const id = useId();
    const yandexCounterId = useCounterId(COUNTER_ID.WebMobile);

    const { embedRef, isInited } = useLoadRamblerEmbed({
      parentId: `${PARENT_ID}${id}`,
      widgetId: `${WIDGET_ID}${id}`,
      url: 'https://embeds.rambler.ru/finance/mortgage-calculator.js',
    });

    return (
      <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
        <rambler-mortgage-calculator
          ref={embedRef}
          yandex-metrica-id={yandexCounterId}
        />
      </div>
    );
  },
);
