import React, { useCallback } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { RecEvents } from 'common/RecEvents';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';

import { Item, ItemPropsType } from '../Item';

type RecItemPropsType = {
  rcmKey: RCM_BLOCK_TYPE;
  clusterId: Card['id'];
  contextItemId: Card['id'];
} & ItemPropsType;

/**
 * Компонент новости для виджета топа новостей.
 * @param props - пропсы
 * @param props.rcmKey - тип блока в котором находятся рекомендации
 * @param props.clusterId - id рекомендованного кластера
 * @param props.contextItemId – id кластера для которого получены рекомендации
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.publicationTime - время публикации;
 * @param props.count - количество комментариев в кластере;
 * @param props.index - индекс айтема для топ100;
 * @param props.onClick - событие по клику для ссылки.
 */
const RecItemComponent = function RecItem({
  rcmKey,
  clusterId,
  contextItemId,
  url,
  title,
  publicationTime,
  count,
  index,
  onClick,
}: RecItemPropsType) {
  const renderRecItem = useCallback(
    (
      ref: React.MutableRefObject<HTMLAnchorElement>,
      onRecClick: () => void,
    ) => (
      <Item
        key={clusterId}
        title={title}
        publicationTime={publicationTime}
        count={count}
        url={url}
        index={index}
        linkRef={ref}
        onClick={() => {
          onRecClick();
          if (onClick) onClick();
        }}
      />
    ),
    [clusterId, count, index, onClick, publicationTime, title, url],
  );

  return (
    <RecEvents
      clusterId={clusterId}
      rcmKey={rcmKey}
      position={index}
      contextItemId={contextItemId}
      // @ts-expect-error: ¯\_(ツ)_/¯
      render={renderRecItem}
    />
  );
};

export const RecItem = withErrorBoundary(RecItemComponent);
