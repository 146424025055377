export enum s3FitType {
  clip = 'clip',
  crop = 'crop',
  scale = 'scale',
  max = 'max',
  cover = 'cover',
}

type S3ResizeArgumentType = {
  number: number;
  width: number;
  height: number;
  fit?: s3FitType;
};

type S3TypeArgumentType = {
  number: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * Дока по вспомогательному API Rstorage https://rstorage.pages.rambler-co.ru/rstorage-docs/
 */

/**
 * Resize картинки из s3
 * @param props - пропсы
 * @param props.number - номер операции по счету
 * @param props.width - будущая ширина картинки
 * @param props.height - будущая высота картинки
 * @param props.fit - режим кропа картинки
 */
export const s3Resize = ({
  number,
  width,
  height,
  fit = s3FitType.cover,
}: S3ResizeArgumentType) =>
  `${number === 1 ? '?' : '&'}img-${number}-resize=${encodeURIComponent(
    `width:${width},height:${height},fit:${fit}`,
  )}`;

/**
 * Конвертирование картинки из s3 в другой доступный формат
 * @param number - номер операции по счету
 * @param format - формат в который надо конвертнуть
 */
export const s3Format = (number: number, format: string) =>
  `${number === 1 ? '?' : '&'}img-format=${encodeURIComponent(format)}`;

/**
 * Обрезка картинки из s3
 * @param props - пропсы
 * @param props.number - номер операции по счету
 * @param props.x - координата x для начала обрезки по оси абсцисс
 * @param props.y - координата y для начала обрезки по оси ординат
 * @param props.width - ширина, сколько надо вырезать
 * @param props.height - высота, сколько надо вырезать
 */
export const s3Crop = ({
  number,
  x = 0,
  y = 0,
  width,
  height,
}: S3TypeArgumentType) =>
  `${number === 1 ? '?' : '&'}img-${number}-crop=${encodeURIComponent(
    `dim:[${x}, ${y}, ${width}, ${height}]`,
  )}`;

/**
 * Функция обработки картинки из s3
 * @param src - url картинки
 * @param operations - массив операций, который надо произвести над картинкой
 * @example
 *  const s3url = s3Image(src, [
 *    s3Resize(1, crop.width, crop.height),
 *    s3Format(2, 'png:min-comp'),
 *  ]);
 */
export const s3Image = (src: string, operations: string[]) =>
  `${src}${operations.reduce((acc, currentValue) => acc + currentValue)}`;
