import { APP_TYPE } from 'config/common/devices';

import s from './styles.module.css';

/**
 * Тип объекта метрик шрифтов
 */
export type FontMetricsType = {
  deviceType: APP_TYPE;
  fontFamily: FONTS_FAMILIES;
  successful: boolean;
  wasCached: boolean;
};

export type CaсheObjectType = {
  family: string;
  weight: string;
};

export enum FONTS {
  manropeExtraBold,
  manropeMedium,
  manropeSemiBold,
}

export enum FONTS_FAMILIES {
  Manrope = 'Manrope',
}

export type FontParam = {
  // Семейство шрифта
  family: FONTS_FAMILIES;
  // Ссылка на шрифт
  source: string;
  // Толщина шрифта
  weight: string;
  // Стиль шрифта
  style: string;

  /**
   * Класс, помечающий, что шрифт не успел
   *  загрузиться за определенный промежуток времени.
   * Класс ДОЛЖЕН совпадать с фоллбечными классами,
   *  описанными в styles.css файле.
   */
  disableClassName: string;
  // Версия стилей с шрифтами-фоллбеками
  fontStyles: string;
};

export const PRELOAD_FONT_URLS = [
  // 'https://static.rambler.ru/fonts/Manrope/regular.woff2',
  'https://static.rambler.ru/fonts/Manrope/medium.woff2',
  'https://static.rambler.ru/fonts/Manrope/semibold.woff2',
  // 'https://static.rambler.ru/fonts/Manrope/bold.woff2',
  'https://static.rambler.ru/fonts/Manrope/extrabold.woff2',
];

/**
 * Чтобы кэшированный шрифт отобразился сразу, необходимо добавить его в preload @see renderPreloadFontLinks.ts
 */
export const FONTS_PARAMS: { [key: number]: FontParam } = {
  [FONTS.manropeExtraBold]: {
    family: FONTS_FAMILIES.Manrope,
    source: 'url(https://static.rambler.ru/fonts/Manrope/extrabold.woff2)',
    weight: '800',
    style: 'normal',
    disableClassName: 'disableManropeExtraBold',
    fontStyles: s.manropeExtraBold,
  },
  [FONTS.manropeMedium]: {
    family: FONTS_FAMILIES.Manrope,
    source: 'url(https://static.rambler.ru/fonts/Manrope/medium.woff2)',
    weight: '500',
    style: 'normal',
    disableClassName: 'disableManropeMedium',
    fontStyles: s.manropeMedium,
  },
  [FONTS.manropeSemiBold]: {
    family: FONTS_FAMILIES.Manrope,
    source: 'url(https://static.rambler.ru/fonts/Manrope/semibold.woff2)',
    weight: '600',
    style: 'normal',
    disableClassName: 'disableManropeSemiBold',
    fontStyles: s.manropeSemiBold,
  },
};
