import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { PROJECT_IDS } from 'config/common/project/typings';

const REGEXP_FILTER_FOR_FINANCE = /currencies|calculators/;
const PATTERN_FILTER_FOR_TRAVEL = 'guide';

type GetTopicAliasesForWidgetsPropsType = {
  projectId: ProjectType['id'];
  topics: StateTopicType[];
  excludedTopicAliases?: TopicType['alias'][];
};

/**
 * Функция получения списка топиков для виджетов на странице вертикали/топика
 * @param props - пропсы
 * @param props.projectId – id вертикали
 * @param props.topics – массив всех топиков
 * @param props.excludedTopicAliases – алиас исключаемого топика
 */
export const getTopicAliasesForWidgets = ({
  projectId,
  topics,
  excludedTopicAliases,
}: GetTopicAliasesForWidgetsPropsType) => {
  const topicsAliases = topics
    .filter((topic) => !excludedTopicAliases?.includes(topic.alias))
    .map((topic) => topic.alias);

  if (projectId === PROJECT_IDS.Finance) {
    return topicsAliases.filter(
      (topic) => topic.search(REGEXP_FILTER_FOR_FINANCE) === -1,
    );
  }

  if (projectId === PROJECT_IDS.Travel) {
    return topicsAliases.filter(
      (topic) => !topic.includes(PATTERN_FILTER_FOR_TRAVEL),
    );
  }

  return topicsAliases;
};
