import cn from 'classnames';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'common/components/Link';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsMobile } from 'common/redux/runtime/selectors';

import { WidgetContent } from './WidgetContent';

import s from './styles.module.css';

const SERVER_VISIBLE_CLASSNAME = 'visible';

type FeedWidgetLayoutPropsType = {
  title?: string | React.ReactNode;
  top100Value: string;
  url?: string;
  styles?: StylesType;
  className?: string;
  isError?: boolean;
  onRefresh?: () => void;
  widgetType?: string;
  isVisibleWithoutJS?: boolean;
  shouldWrapInUL?: boolean;
  withCaptionTitle?: boolean;
  initialHeight?: boolean;
  onMoreNewsClick?: () => void;
};

// TODO(NEWS-11994): поправить объявление компонента

/**
 * Разметка для виджетов
 * @param props - пропсы
 * @param props.children - дочерний элемент;
 * @param props.title - заголовок;
 * @param props.url - ссылка на раздел;
 * @param props.styles - стили для компонента;
 * @param props.className - класс для компонента;
 * @param props.isError - флаг ошибки загрузки новостей;
 * @param props.onRefresh - функция для перезагрузки виджета;
 * @param props.top100Value - значение для топ100;
 * @param props.widgetType – тип виджета;
 * @param props.isVisibleWithoutJS - флаг, что виджет не надо скрывать без js;
 * @param props.shouldWrapInUL - флаг, что надо оборачивать контент в отдельный контейнер списка;
 * @param props.withCaptionTitle - флаг, что тайтл виджета будет иметь тег h2;
 * @param props.initialHeight - флаг, что будет сброшена высота виджета;
 * @param props.onMoreNewsClick – функция клика по кнопке Больше новостей, используется для отправки целей в ЯМ;
 * @param ref – реф для контейнера виджета, чтобы получать высоту выше;
 */
export const FeedWidgetLayoutRedesign = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<FeedWidgetLayoutPropsType>
>(
  (
    {
      children,
      title,
      url = '',
      styles,
      className,
      isError,
      onRefresh,
      top100Value,
      widgetType,
      isVisibleWithoutJS,
      shouldWrapInUL = true,
      withCaptionTitle = false,
      initialHeight = false,
      onMoreNewsClick,
    },
    ref,
  ) => {
    const isMobile = useSelector(selectIsMobile);

    const top100FeedWidget = useTop100AttributeWithValue(
      `feed_widget::${top100Value}`,
    );
    const top100Title = useTop100AttributeWithValue('title');
    const top100Button = useTop100AttributeWithValue('more_button');

    const spanTitle = (
      <span className={cn(s.title, styles?.widgetTitle)}>{title}</span>
    );

    const h2Title = (
      <h2 className={cn(s.title, styles?.widgetTitle)}>{title}</h2>
    );

    const widgetTitleContent = withCaptionTitle ? h2Title : spanTitle;

    const widgetTitle = url ? (
      <a className={s.link} href={url} {...top100Title}>
        {widgetTitleContent}
      </a>
    ) : (
      widgetTitleContent
    );

    return (
      <div
        className={cn(
          s.container,
          styles?.container,
          className,
          isVisibleWithoutJS && SERVER_VISIBLE_CLASSNAME,
          { [s.container_height]: initialHeight, [s.mobile]: isMobile },
        )}
        {...top100FeedWidget}
        ref={ref}
      >
        {title && widgetTitle}

        <WidgetContent
          styles={styles}
          onRefresh={onRefresh}
          widgetType={widgetType}
          isError={isError}
          shouldWrapInUL={shouldWrapInUL}
        >
          {children}
        </WidgetContent>

        {url && (
          <Link
            href={url}
            className={cn(s.moreNewsButton, styles?.button)}
            force
            forceSPA
            onClick={onMoreNewsClick}
            {...top100Button}
          >
            Больше новостей
          </Link>
        )}
      </div>
    );
  },
);
