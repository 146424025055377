import React from 'react';

import { Ad } from 'common/components/Ad';
import { RamblerBudgetCalculator } from 'common/components/RamblerEmbeds/RamblerBudgetCalculator';
import { RamblerCurrencyConverter } from 'common/components/RamblerEmbeds/RamblerCurrencyConverter';
import { RamblerCurrencyExchange } from 'common/components/RamblerEmbeds/RamblerCurrencyExchange';
import { RamblerEmotionalHoroscope } from 'common/components/RamblerEmbeds/RamblerEmotionalHoroscope';
import { RamblerHoroscope } from 'common/components/RamblerEmbeds/RamblerHoroscope';
import { RamblerMortgageCalculator } from 'common/components/RamblerEmbeds/RamblerMortgageCalculator';
import { RamblerTV } from 'common/components/RamblerEmbeds/RamblerTV';
import { RamblerWeather } from 'common/components/RamblerEmbeds/RamblerWeather';
import { BANNERS } from 'config/common/banners/typings';
import { PROJECT_IDS } from 'config/common/project/typings';

import s from './styles.module.css';

// Вертикали, для зеленых рубрик которых не нужны мини-виджеты на втором уровне
export const PROJECTS_WITHOUT_EMBEDS = [
  PROJECT_IDS.Finance,
  PROJECT_IDS.Weekend,
];

export const SLICE_CONFIG = [5, 5, 5, 9, 9] as const;

export const LAST_CHUNK_SIZE = 9;

const NATIVE_BANNER_LIST_CLASS_NAME =
  'banner--native-context--redesign_list_relaunch';
const NATIVE_BANNER_PRELOAD_HEIGHT = 115;

export const INJECTORS = [
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={BANNERS.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedClassName={s.adPlaceholderLoaded}
          preloadHeight={100}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={BANNERS.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          loadedClassName={s.adPlaceholderLoaded}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
        />
      );
    }
  },
  (index: number, puids: PuidsType) => {
    if (index === 3) {
      return (
        <Ad
          key={`injected-ad_${index}`}
          name={BANNERS.Native}
          puids={puids}
          className={NATIVE_BANNER_LIST_CLASS_NAME}
          preloadHeight={NATIVE_BANNER_PRELOAD_HEIGHT}
          loadedClassName={s.adPlaceholderLoaded}
        />
      );
    }
  },
];

/**
 * Когфиг виджетов повертикально
 */
export const WIDGETS_BY_PROJECT_CONFIG: Record<number, React.ReactNode[]> = {
  [PROJECT_IDS.News]: [
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerWeather key="RamblerWeather" />,
    <RamblerTV key="RamblerTV" />,
  ],
  [PROJECT_IDS.Auto]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerTV key="RamblerTV" />,
  ],
  [PROJECT_IDS.Woman]: [
    <RamblerHoroscope key="RamblerHoroscope" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerWeather key="RamblerWeather" />,
  ],
  [PROJECT_IDS.Sport]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerTV key="RamblerTV" />,
  ],
  [PROJECT_IDS.Kino]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Travel]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
  ],
  [PROJECT_IDS.Finance]: [
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
    <RamblerBudgetCalculator key="RamblerBudgetCalculator" />,
    <RamblerMortgageCalculator key="RamblerMortgageCalculator" />,
    <RamblerCurrencyExchange key="RamblerCurrencyExchange" />,
  ],
  [PROJECT_IDS.Doctor]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerEmotionalHoroscope key="RamblerEmotionalHoroscope" />,
    <RamblerHoroscope key="RamblerHoroscope" />,
  ],
  [PROJECT_IDS.Weekend]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerTV key="RamblerTV" />,
  ],
  [PROJECT_IDS.Family]: [
    <RamblerBudgetCalculator key="RamblerBudgetCalculator" />,
    <RamblerMortgageCalculator key="RamblerMortgageCalculator" />,
  ],
  [PROJECT_IDS.Life]: [<RamblerWeather key="RamblerWeather" />],
  [PROJECT_IDS.Pro]: [
    <RamblerBudgetCalculator key="RamblerBudgetCalculator" />,
    <RamblerMortgageCalculator key="RamblerMortgageCalculator" />,
  ],
  [PROJECT_IDS.Dom]: [<RamblerWeather key="RamblerWeather" />],
  [PROJECT_IDS.Ai]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
  ],
  [PROJECT_IDS.Sci]: [
    <RamblerWeather key="RamblerWeather" />,
    <RamblerCurrencyConverter key="RamblerCurrencyConverter" />,
  ],
};

/**
 * Временная подборка топов для боковых колонок спец вертикалей,
 *  пока в колонки нет виджетов рекомендаций.
 */
export const PROJECT_IDS_BY_PROJECT: { [x in PROJECT_IDS]?: PROJECT_IDS[] } = {
  [PROJECT_IDS.Pro]: [PROJECT_IDS.Finance, PROJECT_IDS.Auto],
  [PROJECT_IDS.Dom]: [
    PROJECT_IDS.Family,
    PROJECT_IDS.Doctor,
    PROJECT_IDS.Weekend,
  ],
  [PROJECT_IDS.Family]: [
    PROJECT_IDS.Doctor,
    PROJECT_IDS.Weekend,
    PROJECT_IDS.Dom,
  ],
};
