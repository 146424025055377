import { useEffect, useRef } from 'react';

import { useWidgetStatus } from 'desktop/components/WidgetsStatusProvider';
import { createNode } from 'utils/createNode';

type UseLoadRamblerEmbedPropsType = {
  parentId: string;
  widgetId: string;
  url: string;
  initOnReady?: boolean;
};

/**
 * Хук подключения скриптов для ембедов.
 * @param props - пропсы
 * @param props.parentId - селектор ноды, куда будет вставляться скрипт;
 * @param props.widgetId - id для скрипта;
 * @param props.url - url, по которому подключается скрипт;
 * @param props.initOnReady - флаг, что виджет инициализируется только после срабатывания коллбека ready.
 */
export const useLoadRamblerEmbed = ({
  parentId,
  widgetId,
  url,
  initOnReady,
}: UseLoadRamblerEmbedPropsType) => {
  const embedRef = useRef<HTMLElement>();
  const { statuses, setInitedScript } = useWidgetStatus();

  useEffect(() => {
    // Защита от многоразовой загрузки скрипта
    if (statuses[url]) {
      return;
    }

    createNode({
      tagName: 'script',
      type: 'module',
      src: url,
      id: CSS.escape(widgetId),

      /** @see https://stackoverflow.com/questions/73894096/react-useid-creates-invalid-selector */
      root: `#${CSS.escape(parentId)}`,
    });

    /**
     * Данный блок пока не активен, но оставлен, так как
     *  данный функционал поддерживается.
     * 'ready' триггерится виджетом при полной инициализации.
     *  Благодаря нему можно отловить момент непосредственного старта виджета.
     * Однако на старой версии несколько виджетов подряд триггерили это событие
     *  и вызывали ошибки. Так что данный момент надо использовать с осторожностью.
     * Если он все таки пригодится и будет работать стабильно,
     *  то можно удалить это сообщение.
     * При последней проверке нареканий по работе не было.
     */
    if (initOnReady && embedRef.current) {
      const callback = () => {
        setInitedScript(url);
      };

      const embed = embedRef.current;

      embed.addEventListener('ready', callback);

      return () => embed.removeEventListener('ready', callback);
    }

    setInitedScript(url);
  }, [initOnReady, parentId, setInitedScript, statuses, url, widgetId]);

  return { embedRef, isInited: !!statuses[url] };
};
