import { PROJECT_ALIASES } from 'config/common/project/typings';

/**
 * Массив правил кастомных каноникалов для топиков
 * https://jira.rambler-co.ru/browse/NEWS-11667
 */
export const customCanonicals: {
  input: { projectAlias: PROJECT_ALIASES; path: string };
  output: { projectAlias: PROJECT_ALIASES; path: string };
}[] = [
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'diy',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Woman,
      path: 'home',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Woman,
      path: 'score',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Finance,
      path: 'money',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Finance,
      path: 'markets',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Finance,
      path: 'economics',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'biathlon',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'skiing',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'boxing',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'mma',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'handball',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'summer',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'athletics',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Sport,
      path: 'summer',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Travel,
      path: 'experience',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Travel,
      path: 'lifehack',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'weapon',
    },
    output: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'army',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'troops',
    },
    output: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'army',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'ukraine',
    },
    output: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'Ukraine',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'europe',
    },
    output: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'organization/es',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'usa',
    },
    output: {
      projectAlias: PROJECT_ALIASES.News,
      path: 'USA',
    },
  },
  {
    input: {
      projectAlias: PROJECT_ALIASES.Weekend,
      path: 'items',
    },
    output: {
      projectAlias: PROJECT_ALIASES.Weekend,
      path: 'read',
    },
  },
];
