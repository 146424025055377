import React from 'react';

import { RamblerCurrencyConverter } from 'common/components/RamblerEmbeds/RamblerCurrencyConverter';
import { RamblerCurrencyExchange } from 'common/components/RamblerEmbeds/RamblerCurrencyExchange';
import { RamblerTV } from 'common/components/RamblerEmbeds/RamblerTV';
import { RamblerWeather } from 'common/components/RamblerEmbeds/RamblerWeather';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';

import { RecommendationWidget } from '../RecommendationWidget';

/**
 * Хук, который отдает необходимые виджеты на втором уровне
 * @param clusterId - id кластера
 * @param floorIndex - индекс итерируемого этажа
 */
export const getSecondLevelWidgets = (
  clusterId: Cluster['id'],
  floorIndex: number,
) => {
  if (floorIndex === 0) {
    return (
      <>
        <RamblerCurrencyConverter />
        <RamblerWeather />
      </>
    );
  }

  if (floorIndex === 1) {
    return (
      <>
        <RamblerTV />
        <RamblerCurrencyExchange />
      </>
    );
  }

  return (
    <RecommendationWidget
      rcmKey={RCM_BLOCK_TYPE.topNewsWidgetDesktop}
      clusterId={clusterId}
      isFirstRcmWidget={floorIndex === 2}
    />
  );
};
