import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchRecommendedNews } from 'common/redux/commonData/widgets/recommendationWidget/asyncs';
import {
  selectLoadedRecommendedClusters,
  selectWidgetRecomendedByClusterId,
  selectWidgetsRecomendedClusterInCardData,
  selectWidgetsRecommendedErrorState,
  selectWidgetsRecommendedLoadingState,
} from 'common/redux/commonData/widgets/recommendationWidget/selectors';
import { selectItemExcludedIds } from 'common/redux/selectors';
import { FIFOQueueRcm } from 'desktop/components/RecommendationWidget/utils';
import { useAppDispatch } from 'store/hooks';

type UseRecommendedNewsWidgetType = (
  clusterId: Card['id'],
  isFirstRcmWidget?: boolean,
) => {
  // Массив кластеров для отображения
  clusters: Card[];
  // Состояние виджета (загружается или нет)
  isLoading: boolean;
  // Состояние виджета (есть ошибка или нет)
  isError: boolean;
  // Функция загрузки данных
  fetchData: (sessionId?: string) => void;
};

/**
 * Хук для виджета рекоммендаций.
 * @param clusterId - id кластера, для которого загружаются новости;
 * @param isFirstRcmWidget - флаг, что этот виджет рендерится на первом уровне
 */
export const useRecommendedNewsWidget: UseRecommendedNewsWidgetType = (
  clusterId,
  isFirstRcmWidget,
) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsRecomendedClusterInCardData(clusterId),
    shallowEqual,
  );
  const isLoading = useSelector(
    selectWidgetsRecommendedLoadingState(clusterId),
  );
  const error = useSelector(selectWidgetsRecommendedErrorState(clusterId));
  const widgetData = useSelector(
    selectWidgetRecomendedByClusterId(clusterId),
    shallowEqual,
  );
  const itemExcludedIds = useSelector(selectItemExcludedIds, shallowEqual);
  const loadedRecommendedClusters = useSelector(
    selectLoadedRecommendedClusters,
    shallowEqual,
  );

  const [localSessionId, setLocalSessionId] = useState('');

  const fetchData = useCallback(
    (sessionId?: string) => {
      const inQueue = FIFOQueueRcm.getQueue().find(
        (item) => item.clusterId === clusterId,
      );

      if (!widgetData && !inQueue) {
        FIFOQueueRcm.enqueue({
          clusterId,
          callback: async () =>
            await dispatch(
              fetchRecommendedNews({
                clusterId,
                itemExcludedIds: [
                  ...itemExcludedIds,
                  ...loadedRecommendedClusters,
                ],
                sessionId,
              }),
            ),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusterId, widgetData, itemExcludedIds, loadedRecommendedClusters],
  );

  useEffect(() => {
    if (
      (isFirstRcmWidget && !clusters.length) ||
      (!isFirstRcmWidget && !!localSessionId)
    ) {
      fetchData(localSessionId);
    }
  }, [clusters, isFirstRcmWidget, localSessionId, fetchData]);

  useEffect(() => {
    const interval = setInterval(() => {
      const sessionId = FIFOQueueRcm.getSessionId();

      if (sessionId && localSessionId) {
        clearInterval(interval);

        return;
      }

      if (sessionId && !localSessionId) {
        setLocalSessionId(sessionId);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [localSessionId]);

  return {
    clusters: clusters.filter(Boolean) as Card[],
    isLoading,
    isError: !!error,
    fetchData,
  };
};
