import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { CardWithRcm } from 'common/components/CardWithRcm';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { selectTopicPageIsGreen } from 'common/redux/pages/topic/selectors';
import {
  selectCurrentServerTime,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { BANNERS } from 'config/common/banners/typings';
import { defaultCallback } from 'config/common/common';
import { WINDOW_WIDTH_COMPACT } from 'config/common/css';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';
import { useAppSelector } from 'store/hooks';

import { PROJECTS_WITHOUT_EMBEDS } from '../../constants';
import { getSecondLevelWidgetOneColumn } from '../../utils';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: Card['id'][];
  puids: PuidsType;
  bottomAdName: BANNERS;
  level: number;
  floorIndex: number;
  indexOffset?: number;
  injectElements?: InjectorType;
  widgetsCompact?: React.ReactNode;
  withoutEmbeds?: boolean;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param props - пропсы
 * @param props.clusterIds - список идентификаторов кластеров;
 * @param props.puids - объект настроек для рекламы;
 * @param props.bottomAdName - название баннера в конце блока;
 * @param props.level - этаж для передачи в ЯМ;
 * @param props.floorIndex - индекс итерируемого этажа;
 * @param props.indexOffset - офсет индексов для коректного подсчета в топ100;
 * @param props.injectElements - функция для внедрения компонентов внутрь списка новостей;
 * @param props.withoutEmbeds - флаг, вставки эмбед виджетов.
 */
export const SecondLevelComponent = function SecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  level,
  floorIndex,
  indexOffset = 0,
  injectElements = defaultCallback as InjectorType,
  withoutEmbeds,
}: SecondLevelPropsType) {
  const projectId = useAppSelector(selectProjectId);
  const projectAlias = useAppSelector(selectProjectAlias);
  const serverTime = useAppSelector(selectCurrentServerTime);
  const isGreenTopic = useAppSelector(selectTopicPageIsGreen);
  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });
  const isSecondLevelWithoutEmbeds = Boolean(
    isGreenTopic && PROJECTS_WITHOUT_EMBEDS.includes(projectId),
  );

  const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

  const widgets = getSecondLevelWidgetOneColumn({
    projectId,
    projectAlias,
    clusterId: clusterIds[0],
    topicAlias: getTopicAliasForSideWidget(floorIndex + 1),
    floorIndex,
    isSecondLevelWithoutEmbeds,
    isGreenTopic,
  });

  return (
    <ReachGoalLevelWrapper level={level} className={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements?.(index, puids);
            const news = (
              <CardWithRcm
                key={clusterId}
                currentTime={serverTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + indexOffset}
                isLazy
              />
            );

            if (element) {
              return [news, element];
            }

            return news;
          })}
        </div>
      </div>
      <div className={s.widgets}>
        <div className={cn(s.column, isCompact && s.sticky)}>
          <Ad
            puids={puids}
            className={s.banner}
            name={BANNERS['240x400_2']}
            withoutMarginBottom
            isLazy={false}
          />

          {!withoutEmbeds && widgets?.[0] && (
            <div className={s.embedWidget}>{widgets[0]}</div>
          )}

          {widgets?.[1] && <div className={s.newsWidget}>{widgets[1]}</div>}
        </div>
      </div>
      <div className={s.sideAd}>
        <div className={cn(s.column, s.sticky)}>
          <Ad
            puids={puids}
            name={BANNERS['240x400_2']}
            withoutMarginBottom
            isLazy={false}
          />
        </div>
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </ReachGoalLevelWrapper>
  );
};

export const SecondLevel = memo(SecondLevelComponent);
