import { useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { useTopicsMenu } from 'common/components/TopicsMenu/useTopicsMenu';
import { selectPageName } from 'common/redux/appController';
import { selectTopicsGreenTopicsAliases } from 'common/redux/commonData/topics/selectors';
import { selectTopicPageTopicAlias } from 'common/redux/pages/topic/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { PAGE_TYPE } from 'config/common/router/typings';
import { useAppSelector } from 'store/hooks';
import { getTopicAliasesForWidgets } from 'utils/getTopicAliasesForWidgets';

/**
 * Хук, выдающий функцию, выдающую топик по переданному индексу.
 * @returns Функция, которая возвращает один из топиков в списке по индексу.
 *  Если индекс больше, чем длина массива с топиками, то отсчет начинается с нуля.
 */
export const useTopicAliasesForSideWidgets = () => {
  const projectId = useAppSelector(selectProjectId);
  const pageName = useAppSelector(selectPageName);
  const topicAlias = useAppSelector(selectTopicPageTopicAlias);
  const greenTopicAliases = useAppSelector(
    selectTopicsGreenTopicsAliases,
    shallowEqual,
  );

  const topics = useTopicsMenu(projectId);

  const excludedTopicAliases = useMemo(() => {
    const aliases = [...greenTopicAliases];

    if (pageName === PAGE_TYPE.topic && topicAlias) {
      aliases.push(topicAlias);
    }

    return aliases;
  }, [greenTopicAliases, pageName, topicAlias]);

  const topicsForWidget = useMemo(
    () =>
      getTopicAliasesForWidgets({ projectId, topics, excludedTopicAliases }),
    [projectId, topics, excludedTopicAliases],
  );

  return useCallback(
    (index: number) => topicsForWidget[index % topicsForWidget.length],
    [topicsForWidget],
  );
};
