import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useRcmBlock } from 'common/hooks/useRcm';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';

/**
 * Конфигурация для обертки отправки событий в rcm
 * @param rcmKey - имя блока, в котором используется карточка (для поиска blockID)
 * @param position - номер позиции, под которой выводится карточка
 * @param contextItemId - идентификатор страницы, на которой отображается карточка
 */
export type RcmCardType = {
  rcmKey: RCM_BLOCK_TYPE;
  position: number;
  contextItemId?: string;
};

type RecEventsPropsType<T extends Element> = {
  clusterId: Card['id'] | null;
  render: (ref: React.RefObject<T> | null, onClick: () => void) => JSX.Element;
  rcmKey: RcmCardType['rcmKey'];
  position: RcmCardType['position'];
  contextItemId?: RcmCardType['contextItemId'];
};

/**
 * Обертка отправки событий в RCM
 * @param props - пропсы
 * @param props.clusterId - ID кластера карточки, для которой отправить события;
 * @param props.rcmKey - ключ, по которому храним blockID в конфигурации (topNow например);
 * @param props.position - индекс в списке выводимых карточек;
 * @param props.contextItemId - идентификатор страницы, на которой используется блок (clusterId, pageName или еще что-то);
 * @param props.render - метод, который вернет компонент внутри этой обертки.
 */
const RecEventsComponent = function RecEvents<T extends Element>({
  clusterId,
  rcmKey,
  position,
  contextItemId,
  render,
}: RecEventsPropsType<T>) {
  const { ref, onClick } = useRcmBlock<T>({
    rcmKey,
    position,
    contextItemId,
    clusterId,
  });

  return render(ref, onClick);
};

export const RecEvents = withErrorBoundary(RecEventsComponent);
