import cn from 'classnames';
import _isequal from 'lodash.isequal';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidgetWrapper';
import { CommonTitle, DataTitleType } from 'common/pages/titles/CommonTitle';
import {
  selectWidgetsTopProjectAllClusterIds,
  selectWidgetsTopProjectFirstClusterID,
} from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';
import {
  selectIsRussia,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/common/banners/contants';
import { BANNERS } from 'config/common/banners/typings';
import { CardSwitcher } from 'desktop/components/Card';
import { MainCard } from 'desktop/components/Card/components/MainCard';
import { CARD_TYPES } from 'desktop/components/Card/constants';
import { TopMainNewsWidget } from 'desktop/components/TopNewsWidgets/TopMainNewsWidget';

import { WidgetsColumn } from './WidgetsColumn';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_widget.css';
import 'desktop/css/banner--100_70.css';
import 'desktop/css/banner--native-context_list_new_redesign.css';

const START_CARD_POSITION = 2;

const FIRST_CARD_INDEX = 1;

const TOP_CARDS_COUNT_COMPACT = 8;
const TOP_CARDS_COUNT = 5;

const BOTTOM_CARDS_COUNT_COMPACT = 8;
const BOTTOM_CARDS_COUNT = 5;

const NATIVE_PLACEMENT = 3;

type FirstPageWithWidgetsByTopPropsType = {
  clusterIds: string[];
  puids: PuidsType;
  isCompact?: boolean;
  excludedIds?: Card['id'][];
  dataTitle?: DataTitleType;
};

/**
 * Компонент шаблона первого блока новостей с виджетами мнений, новость часа, и топом разных вертикалей.
 * @param props - пропсы
 * @param props.clusterIds - функция-селектор кластеров для страницы;
 * @param props.puids - настройки для рекламных блоков;
 * @param props.isCompact - флаг, что экран узкий;
 * @param props.excludedIds - исключенные из выдачи кластера для виджета;
 * @param props.dataTitle - данные для заголовка.
 */
const FirstPageWithWidgetsByTopComponent = function FirstPageWithWidgetsByTop({
  clusterIds,
  puids,
  isCompact = false,
  excludedIds = [],
  dataTitle,
}: FirstPageWithWidgetsByTopPropsType) {
  const topWidgetsIds = useSelector(
    selectWidgetsTopProjectAllClusterIds,
    shallowEqual,
  );
  const isRussiaRegion = useSelector(selectIsRussia);
  const isPromoWidgetEnabled = useSelector(selectPromoWidgetEnabled);
  const isPromoWidgetPlaceholderEnabled = useSelector(
    selectPromoWidgetPlaceholderEnabled,
  );
  const hotWidgetId = useSelector(
    selectWidgetsTopProjectFirstClusterID(TAG_WIDGET.HotNews),
    shallowEqual,
  );
  const opinionsWidgetId = useSelector(
    selectWidgetsTopProjectFirstClusterID(TAG_WIDGET.Mnenia),
    shallowEqual,
  );

  const { firstClusterId, firstClusterBlock, secondClusterBlock, nativeIndex } =
    useMemo(() => {
      const slicePlaceFirst = isCompact
        ? TOP_CARDS_COUNT_COMPACT
        : TOP_CARDS_COUNT;
      const slicePlaceSecond = isCompact
        ? slicePlaceFirst + BOTTOM_CARDS_COUNT_COMPACT
        : slicePlaceFirst + BOTTOM_CARDS_COUNT;

      return {
        firstClusterId: clusterIds[0],
        firstClusterBlock: clusterIds?.slice(FIRST_CARD_INDEX, slicePlaceFirst),
        secondClusterBlock: clusterIds?.slice(
          slicePlaceFirst,
          slicePlaceSecond,
        ),
        nativeIndex: NATIVE_PLACEMENT,
      };
    }, [clusterIds, isCompact]);

  const excludeIds = useMemo(
    () => [...excludedIds, ...topWidgetsIds, hotWidgetId, opinionsWidgetId],
    [excludedIds, hotWidgetId, opinionsWidgetId, topWidgetsIds],
  );

  return (
    <div className={s.root}>
      {dataTitle && (
        <div className={s.title}>
          <CommonTitle dataTitle={dataTitle} />
        </div>
      )}

      <ReachGoalLevelWrapper level={1} className={s.clustersList}>
        <ReachGoalFirstCardWrapper>
          <MainCard clusterId={firstClusterId} index={1} />
        </ReachGoalFirstCardWrapper>

        {firstClusterBlock.map((clusterId, index) => {
          const cardPosition = index + START_CARD_POSITION;

          return (
            <CardSwitcher
              key={clusterId}
              clusterId={clusterId}
              type={CARD_TYPES.LIST}
              index={cardPosition}
              isCompactCard
            />
          );
        })}
      </ReachGoalLevelWrapper>

      <div className={s.widgetsColumn}>
        <WidgetsColumn puids={puids} />
      </div>

      <div
        className={cn(s.contextBanner, dataTitle && s.contextBanner_withTitle)}
      >
        <Ad
          name={BANNERS['100x70']}
          className="banner--100_70"
          puids={puids}
          withoutMarginBottom
        />
      </div>

      <div className={cn(s.clustersList, s.clustersListBottom)}>
        {secondClusterBlock.map((clusterId, index) => {
          const cardPosition =
            index + firstClusterBlock.length + START_CARD_POSITION;
          const adKey = `ad_${clusterId}`;

          const item = (
            <CardSwitcher
              key={clusterId}
              clusterId={clusterId}
              type={CARD_TYPES.LIST}
              index={cardPosition}
            />
          );

          if (nativeIndex === index) {
            return [
              <Ad
                key={adKey}
                name={BANNERS.Native}
                puids={puids}
                className={cn(
                  s.adPlaceholder,
                  s.native,
                  'banner--native-context--redesign_list_new',
                )}
                loadedClassName={s.adPlaceholderLoaded}
              />,
              item,
            ];
          }

          return item;
        })}
      </div>

      {!isCompact && (
        <div className={s.latestWidget}>
          {/* Скрыт виджет "Последние новости" - NEWS-11319 */}
          {/* <LatestNewsWidget excludedIds={excludedIds} /> */}
          <TopMainNewsWidget excludeIds={excludeIds} level={1} />
        </div>
      )}

      <div className={cn(s.rightColumn, dataTitle && s.rightColumn_withTitle)}>
        <div className={s.sticky}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={BANNERS.PromoWidget}
              puids={puids}
              isLazy={false}
              // Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
              loadedClassName={s.visible}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            className={s.ad240_400}
            puids={puids}
            name={BANNERS['240x400']}
            isSticky={!isCompact}
            withoutMarginBottom
            isLazy={false}
          />
          {/* Скрыт виджет "Последние новости" - NEWS-11319 */}
          {/* {isCompact && <LatestNewsWidget excludedIds={excludedIds} />} */}
          {isCompact && <TopMainNewsWidget excludeIds={excludeIds} level={1} />}
        </div>
      </div>
    </div>
  );
};

export const FirstPageWithWidgetsByTop = memo(
  FirstPageWithWidgetsByTopComponent,
  (prev, next) =>
    _isequal(prev.clusterIds, next.clusterIds) &&
    _isequal(prev.excludedIds, next.excludedIds) &&
    prev.isCompact === next.isCompact,
);
