import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Head } from 'common/components/headers/Head';
import { PreloadMainImage } from 'common/components/headers/PreloadMainImage';
import { ItemListSchema } from 'common/components/schemaOrg/ItemList';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import {
  selectTopicPageClusterInCardData,
  selectTopicPageIsFormatTopic,
  selectTopicPageItem,
} from 'common/redux/pages/topic/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { useHeader } from 'desktop/hooks/useHeader';
import { textCroppingByWord } from 'utils/textCroppingByWord';

/**
 * Мета страницы топика.
 */
const TopicHeaderComponent = function TopicHeader() {
  const isMobile = useSelector(selectIsMobile);
  const topic = useSelector(selectTopicPageItem, shallowEqual);
  const isFormatTopic = useSelector(selectTopicPageIsFormatTopic);

  const { clustersBySchema } = useHeader(selectTopicPageClusterInCardData);

  const pageTitle = topic?.seo?.title || `Новости ${topic?.name} сегодня`;

  const pageDescription = textCroppingByWord({ str: topic?.seo?.description });

  const pageKeywords =
    topic?.seo?.keywords ||
    `${
      topic?.name
    }, новости, сегодня, ${new Date().getFullYear()}, последние, свежие`;

  return (
    <>
      <PreloadMainImage image={clustersBySchema[0]?.image} />
      <Head
        top100PageType={isFormatTopic ? 'format_rubric' : 'rubric'}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageKeywords={pageKeywords}
        pageUrl={`/${topic?.alias}/`}
        rssTitle={topic?.name}
        rssUrl={`/rss/${topic?.alias}/latest/?limit=100`}
        isMobile={isMobile}
        data-test="topic"
      />
      <ItemListSchema items={clustersBySchema} />
    </>
  );
};

export const TopicHeader = withErrorBoundary(TopicHeaderComponent);
