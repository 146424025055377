import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidgetWrapper';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { selectTopicPageIsGreen, selectTopicPageTopicAlias } from 'common/redux/pages/topic/selectors';
import {
  selectCurrentServerTime,
  selectIsRussia,
  selectProjectId,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import { GREEN_PROJECT_IDS } from 'common/routes/home/constants';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/common/banners/contants';
import { BANNERS } from 'config/common/banners/typings';
import { WINDOW_WIDTH_COMPACT } from 'config/common/css';
import { PROJECT_IDS } from 'config/common/project/typings';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { RecommendationWidget } from 'desktop/components/RecommendationWidget';
import { TopicNewsWidgetRedesign } from 'desktop/components/TopicNewsWidgetRedesign/components/TopicNewsWidget';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';
import { useAppSelector } from 'store/hooks';

import { WIDGETS_BY_PROJECT_CONFIG } from '../../constants';

import s from './styles.module.css';

type FirstLevelPropsType = {
  clusterIds: Card['id'][];
  puids: PuidsType;
};

/**
 * Компонент первого этажа новостной вертикали.
 * @param props - пропсы
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы.
 */
const FirstLevelComponent = function FirstLevel({
  clusterIds,
  puids,
}: FirstLevelPropsType) {
  const projectId = useAppSelector(selectProjectId);
  const isRussiaRegion = useAppSelector(selectIsRussia);
  const isPromoWidgetEnabled = useAppSelector(selectPromoWidgetEnabled);
  const isPromoWidgetPlaceholderEnabled = useAppSelector(
    selectPromoWidgetPlaceholderEnabled,
  );
  const serverTime = useAppSelector(selectCurrentServerTime);
  const topicAlias = useAppSelector(selectTopicPageTopicAlias);
  const isGreenTopic = useAppSelector(selectTopicPageIsGreen);
  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

  const isSpecialGreenTopic = topicAlias
    ? projectId === PROJECT_IDS.Finance && isGreenTopic
    : false;
  const widget = isSpecialGreenTopic
    ? WIDGETS_BY_PROJECT_CONFIG[projectId][3]
    : WIDGETS_BY_PROJECT_CONFIG[projectId][0];

  const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

  return (
    <div className={s.root}>
      <div className={s.main}>
        <ReachGoalLevelWrapper level={1} className={s.news}>
          {clusterIds.map((clusterId, index) => {
            if (index === 0) {
              return (
                <ReachGoalFirstCardWrapper key={clusterId}>
                  <Card
                    key={clusterId}
                    currentTime={serverTime}
                    type="top-first-desktop"
                    clusterId={clusterId}
                    isAlternativeVersion
                    index={index + 1}
                  />
                </ReachGoalFirstCardWrapper>
              );
            }

            return (
              <Card
                key={clusterId}
                currentTime={serverTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + 1}
              />
            );
          })}
        </ReachGoalLevelWrapper>
      </div>
      <div className={s.sideWidgets}>
        <div className={cn(s.column, isCompact && s.sticky)}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={BANNERS.PromoWidget}
              puids={puids}
              isLazy={false}
              // Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
            />
          )}
          <SberWidgetWrapper className={s.sber} />
          <Ad
            puids={puids}
            name={BANNERS['240x400']}
            className={s.banner}
            withoutMarginBottom
            isLazy={false}
          />

          {!!WIDGETS_BY_PROJECT_CONFIG[projectId] && (
            <div className={s.widget}>{widget}</div>
          )}

          <div className={s.newsWidget}>
            {GREEN_PROJECT_IDS.includes(projectId) ||
              isGreenTopic ? (
              <RecommendationWidget
                rcmKey={RCM_BLOCK_TYPE.greenTopNewsWidgetDesktop}
                clusterId={clusterIds[0]}
                isFirstRcmWidget
              />
            ) : (
              <TopicNewsWidgetRedesign
                topicAlias={getTopicAliasForSideWidget(0)}
                newsCount={7}
                level={1}
                withAd
                puids={puids}
              />
            )}
          </div>

          <Ad
            name={BANNERS.Context}
            puids={puids}
            className={cn(
              s.context,
              s.adPlaceholder,
              'banner--native-context_widget_relaunch',
            )}
            loadedClassName={s.adPlaceholderLoaded}
            isLazy={false}
            preloadHeight={100}
          />
        </div>
      </div>
      <div className={s.sideAd}>
        <div className={cn(s.column, s.sticky)}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={BANNERS.PromoWidget}
              puids={puids}
              isLazy={false}
              // Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            puids={puids}
            name={BANNERS['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
        </div>
      </div>
    </div>
  );
};

export const FirstLevel = memo(FirstLevelComponent);
