import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { NewsWidgetBase } from '../NewsWidgetBase';

import { useTopicNews } from './hooks/useTopicNews';

type TopicNewsWidgetPropsType = {
  topicAlias: TopicType['alias'];
  excludedIds?: Card['id'][];
  newsCount?: number;
  level?: number;
  withAd?: boolean;
  puids?: PuidsType;
};

/**
 * Виджет новостей по топику
 * @param props - пропсы
 * @param props.topicAlias - алиас топика;
 * @param props.excludedIds - исключенные из выдачи кластера;
 * @param props.newsCount - кол-во новостей в виджете;
 * @param props.withAd - функция для получения компонента рекламы;
 * @param props.level - номер этажа на котором отображается виджет, нужно для отправки цели;
 * @param props.puids - пуиды для рекламы.
 */
const TopicNewsWidgetComponent = function TopicNewsWidget({
  topicAlias,
  excludedIds = [],
  newsCount,
  withAd,
  level,
  puids,
}: TopicNewsWidgetPropsType) {
  const { title, clusters, isError, fetchData } = useTopicNews({
    topicAlias,
    excludedIds,
    limit: newsCount,
  });

  const clustersByCount = newsCount ? clusters.slice(0, newsCount) : clusters;

  return (
    <NewsWidgetBase
      title={title}
      url={`/${topicAlias}/`}
      alias={topicAlias}
      clusters={clustersByCount}
      isError={isError}
      level={level}
      fetchData={fetchData}
      withAd={withAd}
      puids={puids}
    />
  );
};

export const TopicNewsWidget = memo(
  TopicNewsWidgetComponent,
  (prev, next) =>
    prev.topicAlias === next.topicAlias &&
    _isequal(prev.excludedIds, next.excludedIds) &&
    prev.newsCount === next.newsCount,
);
