import cn from 'classnames';
import React from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import { SkeletonNews } from './SkeletonNews';

import s from './styles.module.css';

type SkeletonsPropsType = {
  className?: string;
};

/**
 * Компонент с набором скелетонов
 * @param props - пропсы
 * @param props.className - дополнительный класс стилей.
 */
const SkeletonsComponent = function Skeletons({
  className,
}: SkeletonsPropsType) {
  return (
    <div className={cn(s.skeletons, className)}>
      <div className={s.skeleton}>
        <SkeletonNews />
      </div>
      <div className={s.skeleton}>
        <SkeletonNews />
      </div>
      <div className={s.skeleton}>
        <SkeletonNews />
      </div>
      <div className={s.skeleton}>
        <SkeletonNews />
      </div>
      <div className={s.skeleton}>
        <SkeletonNews />
      </div>
    </div>
  );
};

export const Skeletons = withErrorBoundary(SkeletonsComponent);
