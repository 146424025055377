import React, { memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { CardContextWrapper } from 'common/components/CardContextWrapper';
import { useBlockInitAtd, usePageViewAtd } from 'common/hooks/useRcm';
import { TopicHeader } from 'common/pages/headers/TopicHeader';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import {
  selectTopicExcludedClustersIds,
  selectTopicPageClusterIds,
  selectTopicPageHasNextPage,
  selectTopicPageIsGreen,
  selectTopicPageItem,
  selectTopicPagePuids,
  selectTopicPageRecommendClusterIds,
} from 'common/redux/pages/topic/selectors';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/common/rcm/typings';
import { Base } from 'desktop/components/Base';
import { BaseRedesignOneColumn } from 'desktop/components/BaseRedesignOneColumn';
import { Trigger } from 'desktop/components/Trigger';
import { useSliceClusters } from 'desktop/hooks/useSliceClusters';
import { useAppSelector } from 'store/hooks';

import s from '../../css/page.css';

import { TopicRecommends } from './TopicRecommends';

/** Компонент страницы топика */
const Topic = memo(() => {
  const topic = useAppSelector(selectTopicPageItem, shallowEqual);
  const topicClustersIds = useAppSelector(selectTopicPageClusterIds, shallowEqual);
  const topicRecommendClustersIds = useAppSelector(
    selectTopicPageRecommendClusterIds,
    shallowEqual,
  );
  const topicExcludedClustersIds = useAppSelector(
    selectTopicExcludedClustersIds,
    shallowEqual,
  );
  const topicPuids = useAppSelector(selectTopicPagePuids, shallowEqual);
  const topicHasNextPage = useAppSelector(selectTopicPageHasNextPage);
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const isGreenTopic = useAppSelector(selectTopicPageIsGreen);

  const rcmBlockType = isGreenTopic
    ? RCM_BLOCK_TYPE.greenTopicDesktop
    : RCM_BLOCK_TYPE.topicDesktop;

  const dataTitle = useMemo(
    () => ({
      name: topic?.name,
      title: topic?.title || `Последние новости ${topic?.name}`,
    }),
    [topic],
  );

  const clusterIds = useMemo(
    () => [...topicClustersIds, ...topicRecommendClustersIds],
    [topicClustersIds, topicRecommendClustersIds],
  );

  const { slicedClusters } = useSliceClusters([
    ...topicClustersIds,
    ...topicRecommendClustersIds,
  ]);

  usePageViewAtd();
  useBlockInitAtd(rcmBlockType);

  return (
    <CardContextWrapper
      selectClustersIds={selectTopicPageClusterIds}
      rcmBlockType={rcmBlockType}
    >
      <div className={s.root}>
        <TopicHeader />
        {forceRedesign ? (
          <BaseRedesignOneColumn puids={topicPuids} clusterIds={clusterIds} />
        ) : (
          <Base
            clusters={slicedClusters}
            puids={topicPuids}
            excludedIds={topicClustersIds}
            dataTitle={dataTitle}
          />
        )}
        <Trigger
          ComponentRecommends={TopicRecommends}
          fetchRecsData={fetchFullRecsData}
          excludedRecsIds={topicExcludedClustersIds}
          rcmBlockType={rcmBlockType}
          hasNextPage={topicHasNextPage}
        />
      </div>
    </CardContextWrapper>
  );
});

export default Topic;
