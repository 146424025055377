import React from 'react';
import { useSelector } from 'react-redux';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectWidgetsVideoClusterID } from 'common/redux/commonData/widgets/videoWidget/selectors';
import { CardSwitcher } from 'desktop/components/Card';

import { STYLES } from './card';

type VideoWidgetWrapperPropsType = {
  type: keyof typeof STYLES;
};

/**
 * Обертка виджета с видео
 * @param props - пропсы
 * @param props.type - тип карточки, используется для выбора компонента для отрисовки.
 */
const VideoWidgetWrapperComponent = function VideoWidgetWrapper({
  type,
}: VideoWidgetWrapperPropsType) {
  const videoClusterId = useSelector(selectWidgetsVideoClusterID);

  // index в данном контексте не имеет значения, поэтому отправляем заглушку
  return <CardSwitcher clusterId={videoClusterId} type={type} index={0} />;
};

export const VideoWidgetWrapper = withErrorBoundary(
  VideoWidgetWrapperComponent,
);
