import cn from 'classnames';
import React, { memo, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectPageName } from 'common/redux/appController/selectors';
import { getIsEditorOrExpertPage, getIsListPage } from 'config/common/common';
import { timeToDuration } from 'utils/date/dtime';
import { useTypograf } from 'utils/typograf';

import { IntegratedVideoPlayer } from '../IntegratedVideoPlayer';

import s from './styles.module.css';

type imageParams = {
  width?: number;
  heigth?: number;
  s3?: boolean;
};

export type CardPropsType = {
  clusterUrl: string;
  recordId?: Card['video']['recordId'];
  isLive?: Card['video']['isLive'];
  imageSrc?: string;
  imageParams?: imageParams;
  title?: string;
  date?: string;
  style: StylesType;
  topicName?: string | null;
  videoDuration?: number;
  commentsCount?: number;
  noComments: boolean;
  top100?: string;
  linkRef?: MutableRefObject<HTMLAnchorElement>;
  onClick?: () => void;
  isCompactCard?: boolean;
  isSecondaryCard?: boolean;
  isLazy?: boolean;
  clusterId: Card['id'] | null;
};

/**
 * (MOBILE | DESKTOP)
 * Общий компонент - шаблон вёрстки карточки новостей для главной вертикали redesign-версии
 * @param props - пропсы
 * @param props.clusterUrl - url кластера;
 * @param props.recordId - id видео в видеоплатформе;
 * @param props.isLive - флаг, что идет видеотрансляция;
 * @param props.imageSrc - url картинки;
 * @param props.imageParams - объект с информацией по картинке;
 * @param props.title - заголовок новости;
 * @param props.date - дата в продовом формате "n минут/секунд/часов назад";
 * @param props.topicName - название рубрики отображаемого кластера;
 * @param props.style - стили, все стили приходят снаружи, сам компонент своих стилей не имеет;
 * @param props.videoDuration - продолжительность видео;
 * @param props.commentsCount - количество комментов на новости;
 * @param props.noComments - флаг, что не отображаем комменты;
 * @param props.top100 - индекс карточки для топ100;
 * @param props.linkRef - реф на обертку ссылки;
 * @param props.onClick - функция, срабатывающая при клике на кнопку;
 * @param props.isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px);
 * @param props.isLazy - флаг ленивой загрузки. работает только для изображений через background-image;
 * @param props.clusterId - id кластера.
 */
const CardLayoutComponent = function CardLayout({
  clusterUrl,
  recordId,
  isLive,
  imageSrc,
  imageParams,
  title,
  date,
  topicName,
  style,
  videoDuration,
  commentsCount,
  noComments,
  top100,
  linkRef,
  onClick,
  isCompactCard,
  isLazy,
  clusterId,
}: CardPropsType) {
  const pageName = useSelector(selectPageName);
  const cookedTitle = useTypograf(title);
  const top100AttributeWithValue = useTop100AttributeWithValue(top100);
  const duration = videoDuration ? timeToDuration(videoDuration) : '';

  const isListPage = getIsListPage(pageName);
  const isEditorOrExpertPage = getIsEditorOrExpertPage(pageName);

  const isModifiedStyles = isListPage || isEditorOrExpertPage;

  return (
    <div
      className={cn(
        style.container,
        isModifiedStyles && style.container_mod,
        !imageSrc && style.container_noImage,
      )}
      {...(top100 && top100AttributeWithValue)}
    >
      <a
        href={clusterUrl}
        aria-label={cookedTitle}
        className={style.card}
        ref={linkRef}
        onClick={onClick}
      >
        <div
          className={cn(
            style.imgContainer,
            isModifiedStyles && style.imgContainer_mod,
            isModifiedStyles && !imageSrc && style.imgContainer_hide,
            isCompactCard && style.imgContainerCompact,
          )}
        >
          {!!duration && <div className={style.duration}>{duration}</div>}
          {!!imageSrc && (
            <RamblerImage
              src={imageSrc}
              alt={title}
              width={imageParams?.width || undefined}
              height={imageParams?.heigth || undefined}
              className={style.img}
              isS3={imageParams?.s3}
              isLazy={isLazy}
              aria-hidden
            />
          )}
          {!!recordId && !!clusterId && (
            <IntegratedVideoPlayer
              recordId={recordId}
              isLive={isLive}
              style={style}
              clusterUrl={clusterUrl}
            />
          )}
        </div>
        <div className={style.allInfoContainer}>
          {!!title && <div className={style.title}>{cookedTitle}</div>}
          {!!topicName && <div className={style.topic}>{topicName}</div>}
          <span className={cn(s.metaInfo, style.metaInfo)}>
            {!!date && date}
            {!noComments && (
              <CommentsCounter
                className={cn(s.icon, style.commentsIcon)}
                count={commentsCount}
              />
            )}
          </span>
        </div>
      </a>
    </div>
  );
};

export const CardLayout = memo(CardLayoutComponent);
