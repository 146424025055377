import React from 'react';
import { ItemList } from 'schema-dts';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { JsonLd } from 'utils/jsond';

import { getItemForItemList } from './utils';

type ItemListSchemaPropsType = {
  items: Cluster[] | { url: string }[];
};

const ItemListSchemaComponent = function ItemListSchema({
  items,
}: ItemListSchemaPropsType) {
  const item = getItemForItemList(items);

  return <JsonLd<ItemList> item={item} />;
};

export const ItemListSchema = withErrorBoundary(ItemListSchemaComponent);
