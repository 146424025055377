import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { DataTitleType } from 'common/pages/titles/CommonTitle';
import { BANNERS } from 'config/common/banners/typings';
import { WINDOW_WIDTH_COMPACT } from 'config/common/css';
import { FirstPageWithWidgetsByTop } from 'desktop/components/FirstPageWithWidgetsByTop';
import { LevelLayout } from 'desktop/components/LevelLayout';
import { WIDGETS_VALUES } from 'desktop/components/LevelLayout/constants';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';

const getGlobalIndex = (clusters: Card['id'][][], startIndex: number) =>
  clusters
    .slice(0, startIndex)
    .reduce((acc, clusterArr) => clusterArr.length + acc, 0);

type BasePropsType = {
  clusters: string[][];
  puids: PuidsType;
  excludedIds: string[];
  dataTitle?: DataTitleType;
};

/**
 * Основа для страницы.
 * @param props - пропсы
 * @param props.clusters - отображаемые кластера;
 * @param props.puids - пуиды страницы;
 * @param props.excludedIds - айдишники исключаемых кластеров;
 * @param props.dataTitle - данные для заголовка.
 */
const BaseComponent = function Base({
  clusters,
  puids,
  excludedIds,
  dataTitle,
}: BasePropsType) {
  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

  const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

  return (
    <>
      {clusters[0] && (
        <FirstPageWithWidgetsByTop
          clusterIds={clusters[0]}
          puids={puids}
          isCompact={isCompact}
          excludedIds={excludedIds}
          dataTitle={dataTitle}
        />
      )}
      {clusters[1] && (
        <>
          <Ad name={BANNERS.Superfooter} puids={puids} />
          <LevelLayout
            clusterIds={clusters[1]}
            puids={puids}
            bannerName={BANNERS.Native}
            widgetName={
              isCompact ? WIDGETS_VALUES.VideoList : WIDGETS_VALUES.VideoCard
            }
            topic={getTopicAliasForSideWidget(0)}
            isCompact={isCompact}
            level={1}
            excludedIds={excludedIds}
            indexOffset={getGlobalIndex(clusters, 1) + 1}
          />
        </>
      )}
      {clusters[2] && (
        <>
          <Ad name={BANNERS.Parallax} puids={puids} />
          <LevelLayout
            clusterIds={clusters[2]}
            puids={puids}
            bannerName={BANNERS.Native}
            topic={getTopicAliasForSideWidget(1)}
            isCompact={isCompact}
            level={2}
            excludedIds={excludedIds}
            indexOffset={getGlobalIndex(clusters, 2) + 1}
          />
        </>
      )}
      {clusters[3] && (
        <>
          <Ad name={BANNERS['100x70']} puids={puids} />
          <LevelLayout
            clusterIds={clusters[3]}
            puids={puids}
            bannerName={BANNERS.Native}
            widgetName={WIDGETS_VALUES.SubscribeWidget}
            topic={getTopicAliasForSideWidget(2)}
            isCompact={isCompact}
            level={3}
            excludedIds={excludedIds}
            indexOffset={getGlobalIndex(clusters, 3) + 1}
          />
        </>
      )}
      {clusters[4] && (
        <>
          <Ad name={BANNERS['100x70']} puids={puids} />
          <LevelLayout
            clusterIds={clusters[4]}
            puids={puids}
            bannerName={BANNERS.Native}
            topic={getTopicAliasForSideWidget(3)}
            isCompact={isCompact}
            level={4}
            excludedIds={excludedIds}
            indexOffset={getGlobalIndex(clusters, 4) + 1}
          />
        </>
      )}
      {clusters[5] && (
        <>
          <Ad name={BANNERS['100x70']} puids={puids} />
          <LevelLayout
            clusterIds={clusters[5]}
            puids={puids}
            bannerName={BANNERS.Native}
            topic={getTopicAliasForSideWidget(4)}
            isCompact={isCompact}
            level={5}
            excludedIds={excludedIds}
            indexOffset={getGlobalIndex(clusters, 5) + 1}
          />
        </>
      )}
      {clusters?.slice(6).map((slicedPart, index) => {
        const offset = getGlobalIndex(clusters, index + 5) + 1;

        return (
          <React.Fragment key={slicedPart[0]}>
            <Ad name={BANNERS['100x70']} puids={puids} />
            <LevelLayout
              clusterIds={slicedPart}
              puids={puids}
              bannerName={BANNERS.Native}
              topic={getTopicAliasForSideWidget(index + 5)}
              isCompact={isCompact}
              level={index + 6}
              excludedIds={excludedIds}
              indexOffset={offset}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export const Base = memo(BaseComponent);
