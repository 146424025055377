import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchProjectTopNews } from 'common/redux/commonData/widgets/topicNewsWidget/asyncs';
import {
  selectTopicNewsWidgetClusterInCardData,
  selectTopicNewsWidgetErrorState,
  selectTopicNewsWidgetLoadingState,
} from 'common/redux/commonData/widgets/topicNewsWidget/selectors';
import { PROJECTS_SHORT_NAME_BY_ID } from 'config/common/project/constants';
import { useAppDispatch } from 'store/hooks';

type UseTopicNewsType = (props: {
  projectId: ProjectType['id'];
  excludedIds?: Card['id'][];
  limit?: number;
}) => {
  // Заголовок для виджета
  title: ATTopic['name'];
  // Массив кластеров для отображения
  clusters: Card[];
  // Состояние виджета (есть ошибка или нет)
  isError: boolean;
  // Функция загрузки данных
  fetchData: () => void;
};

/**
 * Хук для виджета новостей по топику
 * @param props - пропсы
 * @param props.projectId - id проекта
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.limit - кол-во отображаемых кластеров
 */
export const useProjectNews: UseTopicNewsType = ({
  projectId,
  excludedIds,
  limit,
}) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectTopicNewsWidgetClusterInCardData(excludedIds, projectId, limit),
    shallowEqual,
  );
  const error = useSelector(selectTopicNewsWidgetErrorState(projectId));
  const loading = useSelector(selectTopicNewsWidgetLoadingState(projectId));

  const fetchData = useCallback(() => {
    dispatch(fetchProjectTopNews({ projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!clusters.length && !error && !loading) {
      fetchData();
    }
  }, [clusters.length, error, fetchData, loading]);

  return {
    title: PROJECTS_SHORT_NAME_BY_ID[projectId],
    clusters: clusters.filter(Boolean) as Card[],
    fetchData,
    isError: !!error,
  };
};
